import React, { useState, useRef } from "react";
import { Link, Redirect } from "react-router-dom"
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormGroup,   
    Button, 
    Label,
    Input,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Alert,
    Spinner
} from "reactstrap"
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy"
import { Mail, Lock, Check } from "react-feather"
import loginImg from "../assets/img/pages/login.png"
import { history } from "../history"
import "../assets/scss/pages/authentication.scss"

import { EventData, State } from "xstate";
import { EventTypes, Events } from "../machines/login/events";
import { Context } from "../machines/login/state-context";


interface LoginPageProps {
    state: State<Context, Events, any, any>;    
    send: (event: EventTypes, payload?: EventData | undefined) => {};
}

export const LoginPage = ({state,send}:LoginPageProps) => {  

    const [baseURI,setBaseURI] = useState("https://api.zaglist.com/api")
    const readyForLogin = state.matches("SPLASH");
    
    React.useEffect(() => {              
      if(readyForLogin){
          send("SWITCH_ROUTE_LOGIN");
      }
      
    }, [state]);

    const loading =  state.matches({ LOGIN: "VALIDATE_USER" }) || state.matches({ LOGIN: "ACQUIRE_TOKEN" }) ||
    state.matches({ LOGIN: "ACQUIRE_API_URL" }) ||      
    state.matches("SPLASH");

    const [usernameField, setUserNameField] = useState("");
    const [passwordField, setPasswordField] = useState("");

    const inputUsernameRef = useRef<HTMLInputElement>(null);

    const loginLand = true;//state.matches({ LOGIN: "IDLE" }) || state.matches({ LOGIN: "ACQUIRE_TOKEN" });

    React.useEffect(() => {      
      if (inputUsernameRef.current !== null) {
        inputUsernameRef.current!.focus();
      }
    }, [loginLand]);

    function handleLoginClick(e:any)
    {    
        e.preventDefault();
        history.push('/dashboard');
    }

    if(state.matches("DASHBOARD"))
      return (<Redirect to="/dashboard"/>);

    return (
        <div className="full-layout wrapper bg-full-screen-image blank-page dark-layout">
            <div className="app-content">
                <div className="content-wrapper">
                <div className="content-body">
                    <div className="flexbox-container">
                    <main className="main w-100">

                    <Row className="m-0 justify-content-center">
        <Col
          sm="8"
          xl="7"
          lg="10"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication login-card rounded-0 mb-0 w-100">
            <Row className="m-0">
              <Col
                lg="6"
                className="d-lg-block d-none text-center align-self-center px-1 py-0"
              >
                <img src={loginImg} alt="loginImg" />
              </Col>
              <Col lg="6" md="12" className="p-0">
                <Card className="rounded-0 mb-0 px-2 login-tabs-container">
                  <CardHeader className="pb-1">
                    <CardTitle>
                      <h4 className="mb-0">Login</h4>
                    </CardTitle>
                  </CardHeader>
                  <p className="px-2 auth-title">
                    Welcome back, please login to your account.
                  </p>
                  <CardBody className="pt-1">
                    <Form action="/" >
                    {loginLand && (
                    <>
                    <FormGroup className="form-label-group position-relative has-icon-left">
                    <Input
                        type="email"
                        placeholder="Email"   
                        value={usernameField}
                        onChange={({ target }) => setUserNameField(target.value)}
                        innerRef={inputUsernameRef}       
                        onKeyPress={(event) => {
                          if (
                            event.key === "Enter" &&
                            usernameField &&
                            passwordField
                          ) {
                            send("SUBMIT_LOGIN_CREDENTIALS", {
                              uri: baseURI,
                              username: usernameField,
                              password: passwordField,
                            });
                          }
                        }}                                     
                    />
                    <div className="form-control-position">
                        <Mail size={15} />
                    </div>
                    <Label>Email</Label>
                    </FormGroup>
                    <FormGroup className="form-label-group position-relative has-icon-left">
                    <Input
                        type="password"
                        placeholder="Password"    
                        value={passwordField}         
                        onChange={({ target }) => setPasswordField(target.value)}        
                        onKeyPress={(event) => {
                          if (
                            event.key === "Enter" &&
                            usernameField &&
                            passwordField
                          ) {
                            send("SUBMIT_LOGIN_CREDENTIALS", {
                              uri: baseURI,
                              username: usernameField,
                              password: passwordField,
                            });
                          }
                        }}   
                    />
                    <div className="form-control-position">
                        <Lock size={15} />
                    </div>
                    <Label>Password</Label>
                    </FormGroup>
                    <FormGroup className="d-flex justify-content-between align-items-center hidden">
                    <Checkbox
                        color="primary"
                        icon={<Check className="vx-icon" size={16} />}
                        label="Remember me"                        
                    />
                    <div className="float-right">
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                    </FormGroup>
                    <div className="d-flex justify-content-between">                               
                    <Button 
                    disabled={!usernameField && !passwordField}
                    color="primary" 
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      
                      if (usernameField && passwordField) {
                        send("SUBMIT_LOGIN_CREDENTIALS", {
                          uri: baseURI,
                          username: usernameField,
                          password: passwordField,
                        });
                      }
                    }}
                    >                     
                        Login
                    </Button>
                    </div>
                    </>
                    )}
                    {state.context.error && (   
                    <div className="d-flex justify-content-between mt-1">                               
                    <Alert color="danger">                      
                    {state.context.error}
                    </Alert>
                    </div>
                    )}
                    {loading && (            
                      <div className="d-flex justify-content-between mt-1">                               
                      <Spinner size="lg"  className="primary float-right"/>
                      </div>
                    )}
                </Form>
                </CardBody>          
                 
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

                    </main>
                    </div>
                </div>
                </div>
            </div>
        </div>    
    );
}

export default LoginPage;