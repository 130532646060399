import React, { useState,useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import { Edit, Trash, Lock, Check } from "react-feather"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import {
    Row,    
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Col,
    Input,  
    Media,  
    Table,
    CustomInput,
    Form,
    Button,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody
  } from "reactstrap"
  import userImg from "../assets/img/portrait/small/avatar-s-18.jpg"
  import "../assets/scss/pages/users.scss"
  import {User} from '../contracts/response-models/user'
  import {getUserInfoService, getUserProfileInfoService} from "../machines/listing-machine/services"
import { UserFPModal } from "../components/Users/user-fp-modal";
import { useModal } from "../components/MyListings/useModal";
import { ActionModalTypes } from "./MyListings";
import { ToastContainer } from "react-toastify";
import { ProfileSection } from "../contracts/response-models/profile-section";

export const ProfilePreview = () => {

  const [loading,setLoading] = useState(false);

  const [user, setUser] = useState<User>({userId:0});

  const { isModalOpened, toggleModal } = useModal();
const [openModal, setOpenModal] = useState<ActionModalTypes>();
const [readyState, setReadyState] = useState(true);
const [profileSection, setProfileSection] = useState<ProfileSection[]>();
  useEffect(() => {    
    async function getUserInfo() {      
      setLoading(true);  
      const userProfileinfo = await getUserProfileInfoService();  
      setUser(userProfileinfo.user);
      if (userProfileinfo.profileSections.length > 0) {
        setProfileSection(userProfileinfo.profileSections);
      }
      setLoading(false);
    }
    getUserInfo();
},[]);

const openFeaturedUserModal = () => {
   
  toggleModal();    
}

    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="Profile Preview" breadCrumbParent="Profile" breadCrumbActive="Profile Preview" />
            <ToastContainer />

            <Card>    

            <Modal
              id="actionModal"
                  isOpen={isModalOpened}
                  toggle={toggleModal}
                  className="modal-dialog-centered"
                >
                <UserFPModal user={user} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
   
          </Modal>  
        <CardHeader>
                <CardTitle>Agent Information</CardTitle>
              </CardHeader>
              <CardBody>
              {loading ? (
                <div className="d-flex justify-content-between mt-1">                               
                <Spinner size="lg"  className="primary float-right"/>
              </div>
              ): (

              
              <Row className="mx-0" col="12">
                  <Col className="pl-0" sm="12">
                    <Media className="d-sm-flex d-block">
                      <Media className="mt-md-1 mt-0" left>
                        <Media
                          className="rounded mr-2"
                          object
                          src={user?.userPhoto}
                          alt={user.firstName}
                          title={user.firstName}
                          height="112"
                          width="112"
                        />
                      </Media>
                      <Media body>
                        <Row className="ml-5">
                          <Col sm="12"  md="12" lg="12">
                            <div className="users-page-view-table">
                              <div className="d-flex user-info font-weight-bold">                                
                                <div><h3>{user.firstName} {user.lastName}</h3></div>                             
                                
                              </div>
                              <div className="d-flex user-info">                               
                              <div> {user.title} </div>
                              </div>
                              <div className="d-flex user-info">
                                <div className="user-info-title font-weight-bold">
                               {user.cellno} Cell
                                </div>
                             
                              </div>
                              <div className="d-flex user-info">
                                <div className="user-info-title ">
                                {user.companyName} <br/>
                               {user.streetAddress}<br/>
                                {user.city}, {user.stateCD} {user.zip}<br/>
                               {user.tel} Office
                                </div>
                             
                              </div>
                              <div className="d-flex user-info font-weight-bold">                                
                                <div><h3>About Me</h3></div>   
                                                
                              </div>
                              <div className="d-flex user-info">                               
                              <div> {profileSection != undefined && (
                        profileSection?.map(function (profileInfo, i) {
                          return (<>
                          <h6>{profileInfo.title}</h6>
                          <p>{profileInfo.section}</p>
                           </>)
                        }))}
                          </div>        
                              </div>
                              <div className="d-flex user-info">                               
                              <div>Website : {user.website}</div>        
                              </div>
                            </div>
                          </Col>
                          <Col md="12" lg="5">
                    
                          </Col>
                        </Row>
                      </Media>
                    </Media>
                  </Col>
                  <Col className="mt-1 pl-0" sm="2">
                    <Button className="mr-1" color="primary" outline>
                      <Link to="/edit-profile">
                        <Edit size={15} />
                        <span className="align-middle ml-50">Edit</span>
                      </Link> 
                    </Button>                  
                  </Col>
                  <Col className="mt-1 pl-0" sm="10">
                    <Button type="button" className="btn btn-primary" color="primary" onClick={openFeaturedUserModal} >
                                            
                     Make Featured 
                      
                    </Button>                  
                  </Col>
                </Row>
                )}
              </CardBody>
           
           
            </Card>
        </React.Fragment>
    )
}
export default ProfilePreview;