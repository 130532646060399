import React, { useEffect,useState } from "react";
import {
    Label,
    Row,
    Card,
    CardBody,
    Col,   
    Input,  
    Spinner
  } from "reactstrap"


import classnames from "classnames";
import {Property} from '../../../contracts/response-models/property'
import { flatten } from "xstate/lib/utils";
import { Redirect } from "react-router-dom";

interface PropertyPhotoStepProps {
  property:Property,
  setProperty:any;  
  IsPropertySaved:boolean;  
  loading?:boolean;
  NewSavedPropertyId:number;
}

interface fileUpload{
  file:any;
  url?:string;
  uploaded?:boolean;
 
}

export const PhotoStep = ({ property, setProperty,IsPropertySaved,loading,NewSavedPropertyId}:PropertyPhotoStepProps) => {   
  
  const [isUploading,setIsUploading] = useState(false);
 
  const handlePhotoUploadChange = (e:any) =>{
    
    setIsUploading(true);
    const filesUpload = e.target.files;  
    const formData = new FormData()
    for(var i=0;i<filesUpload.length;i++)
    {
      formData.append('files', filesUpload[i])
    }
    uploadFiles(formData);
  }

  //TODO: Move this function in API
  const uploadFiles=(formData:any)=>{
    fetch('https://api.zaglist.com/api/upload', {  
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {    
     
      var filearray = [];
      for(var i=0;i<data.length;i++)
      {
        filearray.push(data[i].fileName);
      }     

      var emptyArr = new Array<string>()
      var arrPhotos = property.imageNames == undefined ? emptyArr : property.imageNames;
      arrPhotos = arrPhotos.concat(filearray);

      setProperty({...property,imageNames:arrPhotos});   
      //setProperty({...property,imageNames:property.imageNames?.concat(filearray)});   
      setIsUploading(false); 
    })
    .catch(error => {
      console.error(error)
    }) 
  }
  
  const handlePhotoDelete =(e:any) =>{
    e.preventDefault();
    const index = property.imageNames?.indexOf(e.target.value);
    let images = property.imageNames;
    if (index!=undefined && index > -1) {  
      images?.splice(index, 1);      
    }
    setProperty({...property,imageNames: images});  
  }
    if(property.id!=undefined)
    {
      if(IsPropertySaved)
        return (<Redirect to="/listings" />);
    }else
    {
      if(NewSavedPropertyId!=undefined)
      {
        if(IsPropertySaved)
        return (<Redirect to={`/listings/publish/${NewSavedPropertyId}`} />);
      }
      
    }
     

    return (  
        
        <Row>
        <Col md="12" sm="12">
        {loading ? (   
            <h3 className="mb-2 mt-2 text-center">Saving Property...  </h3>
          
        ) : (      <> 
    <h3 className="mb-2 mt-2 text-center">PHOTOS  </h3>
          <div className="form-label-group mt-2">
            <Input
              multiple
              type="file"
              className={classnames("React",{
                invalid: (property.imageNames == undefined || property.imageNames.length==0)
              })}
              name="propertyphoto[]"
              id="propertyphoto"             
              placeholder="Upload Photos"
              onChange={handlePhotoUploadChange}
            />    
            {isUploading && ("Uploading...")}  
         
          </div>
         
          <div className="row" id="images_container">
            {property.imageNames?.map((f,index)=>(
              <Col md="3" sm="3" lg="3"  >
                <Card>
                <img className="img-responsive" height="100"  src={f.toString()}/>               
                <CardBody>
                  <button onClick={handlePhotoDelete} value={f.toString()}>X</button>                  
                </CardBody>
                </Card>
              </Col>
            )
            )}
          </div>
          </>
          )}   
        </Col>
      </Row>   
    )
}