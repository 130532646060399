import React,{useState,useEffect} from "react";
 
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,
   
} from "reactstrap"

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import {Property} from '../../contracts/response-models/property'
import { PropertyHeadInfo } from "./property-head-info-modal";

import { ExtendListing } from '../../contracts/response-models/extendlisting'
import { ToastContainer, toast } from "react-toastify";

import { saveExtendListingService, getUserInfoService, getStripeClientSecretListingservice, createInvoiceService } from '../../machines/listing-machine/services'
import { setDatePickerFormat } from "../../utils/date-helper";
import { StripePromise } from "../../utils/loadStripe";

import stripelogo from "../../assets/img/logo/powered_by_stripe.png"
import { StripePayment } from "../Payment/StripePayment";
import { User } from "../../contracts/response-models/user";
import { StripeSecret } from "../../contracts/response-models/stripe-client-secret";
import { PaymentType } from "../../contracts/enums/payment-type";
 

interface PropertyIdProps {  
    property?:Property,
    toggle:any; 
    readyState:any;
  setReadyState:any;
}


export const StripeExtendListingForm = ({ property,toggle,readyState,setReadyState}:PropertyIdProps) => {  
  const stripePromise = StripePromise();

  const stripe = useStripe();
  const elements = useElements(); 

  const [user, setUser] = useState<User>({ userId: 0 }); 
  const [buttonText, setButtonText] = useState('Save');  
  const[paymentError,setPaymentError]=useState('');
  const [paymentloading, setPaymentLoading] = useState(false);
  const [stripeSecret, setStripeSecret] = useState<StripeSecret>(
    {
      secret: '',
      amount: 0,
      days:0,
      discount:0,
    }
  );
  
  const [loading, setLoading] = useState(false);
  const [extendListing, setExtendListing] = useState<ExtendListing>(
    { endDate:property?.endDate}
  );

  useEffect(() => {
    async function setUserInfo() {
      setLoading(true);      
      const userinfo = await getUserInfoService();
      setUser(userinfo);

      setLoading(false);
    
    }

    setUserInfo();
  }, [user.userName]);
  
  
  const handleExpirationDateChange = async (e: any) => {
    setLoading(true);  
    setExtendListing({ ...extendListing, endDate: e.target.value });
   let endDate = e.target.value;
   const striperesponse = await getStripeClientSecretListingservice(property?.startDate,endDate,user.userName);
   setStripeSecret(striperesponse);
   setButtonText('Pay Now');
   setLoading(false);
  }

  const saveExtendListing = async (e: any) => {
    e.preventDefault()
    setPaymentError('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }    
     const client_secret = stripeSecret?.secret;
    
     const card = elements.getElement(CardElement);
     if(!card){
       return;
     }
     setPaymentLoading(true);
    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: card,        
        billing_details: {
          name: 'Sikander Singh Shekhawat',
          address:{
            line1:'87, Shiv Vihar, Jhotwara',
            city: 'NY',
            country: 'US'
          },         

        },
      }
    });
    if(result)
    {
      setPaymentLoading(false);
    }
    if (result.error) {
      if(result.error.message!=undefined)
      {
        setPaymentError(result.error.message);
      }  
       
      //console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result?.paymentIntent?.status !== undefined) {
        if (result.paymentIntent.status === 'succeeded') {
          setLoading(true);
          const invoicesaveresponse = createInvoiceService(PaymentType.ListingPayment,stripeSecret.days,property?.id,result.paymentIntent.id);
          
          toast.success("Payment Processed Successfully", {
            position: toast.POSITION.TOP_RIGHT
          })
          
          setLoading(false);
          toggle();
          setReadyState(true);  
        }
      }

    }    
   
  }
        return (     
         
            <>         

          
                <PropertyHeadInfo property={property}    />

              <FormGroup>
                <Label for="listingPrice">Listing Date : </Label>
             <strong>  {property?.startDate}</strong>

              </FormGroup>
              <FormGroup>
                <Label for="listingPrice">Current Expiration Date : </Label>
                <strong>   {property?.endDate}</strong>

              </FormGroup>
 
              <FormGroup>
                <Label for="address">New Expiration Date</Label>
                <Input
                      type="date"
                      id="date"
                      placeholder="Date"
                      value={(extendListing.endDate == undefined ? "" : setDatePickerFormat(extendListing.endDate))}
                      onChange={handleExpirationDateChange}
                    />

              </FormGroup>
              {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
          <>
              {buttonText=="Save" ? (
                <>
        
              <FormGroup>

                <Row className="mb-2 mt-0">
                  <Col md="4" sm="6" className="text-right">

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1 mb-1"
                      onClick={saveExtendListing}                        
                    >
                      {buttonText}
                </Button>
                  </Col>
                  <Col md="4" sm="6" className="text-right">
                  </Col>
                </Row>

              </FormGroup>
              </>
              ):(
                <>
                  <FormGroup>
          <Label for="nameVertical">Listing Days : </Label>  <strong> {stripeSecret?.days} </strong>


        </FormGroup>
                  <FormGroup>
          <Label for="nameVertical">Amount : </Label>  <strong>$ {stripeSecret?.amount/100} </strong>


        </FormGroup>
        <StripePayment error={paymentError} />
             
                      <FormGroup>
        
                        <Row className="mb-2 mt-0">
                          <Col md="4" sm="6" className="text-right">
        
                          </Col>
                          <Col md="4" sm="6" className="text-right">
                          { paymentloading ?   (        
      
      <Button
      color="primary"
      type="submit"
      className="mr-1 mb-1"                              
    >
      Processing...
</Button>
     ):( 
                            <Button
                              color="primary"
                              type="submit"
                              className="mr-1 mb-1"
                              onClick={saveExtendListing}                        
                            >
                              {buttonText}
                        </Button> )}
                          </Col>
                          <Col md="4" sm="6" className="text-right">
                          </Col>
                        </Row>
        
                      </FormGroup>
                      </>
              )
} </>
        )}
                     
         </>
    )
}
    