import React, { useState, useRef, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Label,
    Form,
    Nav,    
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner
  } from "reactstrap"
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy"
import { Mail, Lock, Check } from "react-feather"
import { history } from "../history"
import registerImg from "../assets/img/pages/register.jpg"
import "../assets/scss/pages/authentication.scss" 
import {editUserService, getUserInfoService, listStatesService, registerUserService} from '../machines/listing-machine/services'
import { ValidationMessage } from "../components/@vuexy/validation-message"; 
import PhoneInput from "react-phone-input-2";
import "../components/Register/css/custom-register.css"
import Select from "react-select"
import { User } from "../contracts/response-models/user";
import { toast, ToastContainer } from "react-toastify";

  
export const CompleteProfilePage = () => {

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>({userId:0});
  const [validationMessage, setValidationMessage] = useState<string>();
  const defaultOption = { label: "Select", value: "" }
  const [selectedState, setSelectedState] = React.useState(defaultOption);
  const [stateItems, setStateItems] = React.useState([
    defaultOption
  ]);

  useEffect(() => {    
    async function loadStats() {
        const userinfo = await getUserInfoService(); 
        setUser(userinfo);
        
        const states = await listStatesService();    
        const stateOptions = states.map((state) => ({ label:state.stateTitle , value: state.stateCD }))
        setStateItems(stateOptions);

        
       
    }
    loadStats();
  },[]);

    let handleSaveInforamtion = async (e:any) => {
        
        e.preventDefault()   
        var isValidated = true; 
         
        if (user?.tel == undefined || user.tel == "") {
          setUser({ ...user, tel: '' });
          isValidated = false
        }
        if (user?.zip == undefined || user.zip == "") {
          setUser({ ...user, zip: '' });
          isValidated = false
        }
        if (user?.stateCD == undefined || user.stateCD == "") {
          setUser({ ...user, stateCD: '' });
          isValidated = false
        }
        if (user?.city == undefined || user.city == "") {
          setUser({ ...user, city: '' });
          isValidated = false
        }
        if (user?.streetAddress == undefined || user.streetAddress == "") {
          setUser({ ...user, streetAddress: '' });
          isValidated = false
        }
        if (user?.companyName == undefined || user.companyName == "") {
          setUser({ ...user, companyName: '' });
          isValidated = false
        } 
        if (user?.cellno == undefined || user.cellno == "") {
            setUser({ ...user, cellno: '' });
            isValidated = false
          } 
          
        if (isValidated == true && user!=undefined) {
        setLoading(true); 
        const response = await editUserService(user,0); 
        toast.success("Profile Updated Successfully", {              
          position: toast.POSITION.TOP_RIGHT
        })   
        window.location.href="/dashboard";
        setLoading(false);
        }
    }

    return (
        <div className="full-layout wrapper bg-full-screen-image blank-page dark-layout">
            <div className="app-content">
                <div className="content-wrapper">
                <div className="content-body">
                    <div className="flexbox-container">
                    <main className="main w-100">

        <Row className="m-0 justify-content-center">
        <Col
          sm="8"
          xl="7"
          lg="10"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication rounded-0 mb-0 w-100">
            <Row className="m-0">
              <Col
                lg="6"
                className="d-lg-block d-none text-center align-self-center px-1 py-0"
              >
                <img className="mr-1" src={registerImg} alt="registerImg" />
              </Col>
              <Col lg="6" md="12" className="p-0">
                <Card className="rounded-0 mb-0 p-2">
                  <CardHeader className="pb-1 pt-50">
                    <CardTitle>
                      <h4 className="mb-0">Complete your profile</h4>
                    </CardTitle>
                  </CardHeader> 
                  <CardBody className="pt-1 pb-50">
                  <ToastContainer />
      
                  {loading ? (<div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>) : (
                  <Form action="/" onSubmit={handleSaveInforamtion}>  
                        <FormGroup className="form-label-group1 show_label">
                        <Label>Cell No</Label> 
                        <PhoneInput  country={'us'}  value={user?.cellno} 
                        placeholder="Cell"
                 onChange={(value)=> { 
                    setUser({ ...user, 
                        title: '', 
                        website:'',
                        cellno: value
                        
                     });
                     }}   /> 
                        
                        <ValidationMessage isInvalid={(user?.cellno == undefined) || (user.cellno != '') ? false : true} errorMessage="Please enter cell no" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Company Name"
                            value={user?.companyName}    
                            onChange={(e)=> { setUser({ ...user, companyName: e.target.value })}}                        
                        />
                        <Label>Company Name</Label>
                        <ValidationMessage isInvalid={(user?.companyName == undefined) || (user.companyName != '') ? false : true} errorMessage="Please enter company name" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Street Address"
                            value={user?.streetAddress}    
                            onChange={(e)=> { setUser({ ...user, streetAddress: e.target.value })}}                        
                        />
                        <Label>Street Address</Label>
                        <ValidationMessage isInvalid={(user?.streetAddress == undefined) || (user.streetAddress != '') ? false : true} errorMessage="Please enter street address" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="City"
                            value={user?.city}    
                            onChange={(e)=> { setUser({ ...user, city: e.target.value })}}                        
                        />
                        <Label>City</Label>
                        <ValidationMessage isInvalid={(user?.city == undefined) || (user.city != '') ? false : true} errorMessage="Please enter city" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Select    
                id="ddlState"        
                className="React"
                classNamePrefix="select" 
                defaultValue={selectedState}   
                options={stateItems}    
                value={selectedState} 
                onChange={(val:any)=> { setSelectedState(stateItems.filter(option => option.value==val.value)[0]);
                  setUser({...user,stateCD:val.value});}}
                />
                         
                        <Label>State</Label>
                        <ValidationMessage isInvalid={(user?.stateCD == undefined) || (user.stateCD != '') ? false : true} errorMessage="Please select state" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Zip"
                            value={user?.zip}    
                            onChange={(e)=> { setUser({ ...user, zip: e.target.value })}}                        
                        />
                        <Label>Zip</Label>
                        <ValidationMessage isInvalid={(user?.zip == undefined) || (user.zip != '') ? false : true} errorMessage="Please enter zipcode" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group1 show_label">
                        <Label>Office Tel</Label> 
                        <PhoneInput  country={'us'}  value={user?.tel} 
                        placeholder="Office Tel"
                 onChange={(value)=> { setUser({ ...user, tel: value })}}   /> 
                        
                        <ValidationMessage isInvalid={(user?.tel == undefined) || (user.tel != '') ? false : true} errorMessage="Please enter office tel no" />
                     
                        </FormGroup>
                  
                        <div className="d-flex justify-content-between">
                         
                        <Button Ripple color="primary" type="submit">
                            Save information
                        </Button>
                        </div>
                    </Form>
                  )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      </main>
      </div>
      </div>
      </div>
      </div>
      </div>
    )
}

export default CompleteProfilePage;