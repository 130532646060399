import React, { useEffect, useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
  FormGroup,
  Spinner
} from "reactstrap"
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { ValidationMessage } from "../@vuexy/validation-message";
import { Property } from "../../contracts/response-models/property";
import { User } from '../../contracts/response-models/user'
import { getUserInfoService } from "../../machines/listing-machine/services"
import { setDatePickerFormat } from "../../utils/date-helper";

import { StripeSecret } from '../../contracts/response-models/stripe-client-secret'
import {PaymentType} from '../../contracts/enums/payment-type'

import { getStripeClientSecretListingservice } from "../../machines/listing-machine/services"

import {createInvoiceService} from "../../machines/listing-machine/services"
import "../../assets/scss/pages/stripe-form.scss"; 
import {StripePayment} from './StripePayment'

import { toast, ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";
interface paymentProps {
  property: Property;
}
export const StripePaymentForm = ({ property }: paymentProps) => {
  
  const [loading, setLoading] = useState(false);
  const [isSavedSuccess, setSavedSuccess] = useState(false); 
  const [paymentloading, setPaymentLoading] = useState(false);
  const[paymentError,setPaymentError]=useState('');
  const stripe = useStripe();
  const elements = useElements(); 
  const [user, setUser] = useState<User>({ userId: 0 });  
  const [stripeSecret, setStripeSecret] = useState<StripeSecret>(
    {
      secret: '',
      amount: 0,
      days:0,
      discount:0,
    }
  );
 



  useEffect(() => {
    async function setUserInfo() {
      setLoading(true);      
      const userinfo = await getUserInfoService();
      setUser(userinfo);

      console.log('Email :'+user.userName);
      
      const striperesponse = await getStripeClientSecretListingservice(property.startDate,property.endDate,user.userName);
      setStripeSecret(striperesponse);
     // console.log('Stripe Response :'+striperesponse);
      setLoading(false);
    
    }

    setUserInfo();
  }, [user.userName]);

  const handleStripePaymentSubmit = async (e: any) => {
    
    e.preventDefault()
    setPaymentError('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }    
     const client_secret = stripeSecret?.secret;
    
     const card = elements.getElement(CardElement);
     if(!card){
       return;
     }
     setPaymentLoading(true);
    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: card,        
        billing_details: {
          name: user.firstName+" "+user.lastName,
          address:{
            line1:user.streetAddress,
            city: user.city,
            country: 'US'
          },         

        },
      }
    });
    if(result)
    {
      setPaymentLoading(false);
    }
    if (result.error!=undefined) {
      if(result.error.message!=undefined)
      {
        setPaymentError(result.error.message);
      }    
       
      //console.log(result.error.message);
    } else {
      setLoading(true);   
      // The payment has been processed!
      if (result?.paymentIntent?.status !== undefined) {
        if (result.paymentIntent.status === 'succeeded') {

          const invoicesaveresponse = createInvoiceService(PaymentType.ListingPayment,stripeSecret.days,property.id,result.paymentIntent.id);
          
          toast.success("Payment Processed Successfully", {
            position: toast.POSITION.TOP_RIGHT
          })
          setLoading(false);   
          setSavedSuccess(true);  
        }
      }

    }  

  }

  if(isSavedSuccess)
  {
    return (<Redirect to="/listings" />)
  }

  return (
    <>
   {loading ? (
            <div className="d-flex justify-content-between mt-1">                               
            <Spinner size="lg"  className="primary float-right"/>
          </div>
          ):(
           
      <form onSubmit={handleStripePaymentSubmit} className='form-container'  >
        <ToastContainer />
        <FormGroup>
          <Label for="name">Name : </Label>
          <strong>{user?.firstName}</strong>
        </FormGroup>
        <FormGroup>
          <Label for="email">Email : </Label>  <strong>{user?.userName} </strong>

        </FormGroup>
        <FormGroup>
          <Label for="startDate">Listing start Date : </Label>  <strong>{(setDatePickerFormat(property.startDate))}</strong>


        </FormGroup>
        <FormGroup>
          <Label for="endDate">Listing End Date : </Label>  <strong>{(setDatePickerFormat(property.endDate))}</strong>


        </FormGroup>
        <FormGroup>
          <Label for="nameVertical">Amount : </Label>  <strong>$ {stripeSecret?.amount/100} </strong>


        </FormGroup>
      
            <StripePayment error={paymentError} />
            { paymentloading ?   (        
      
      <button type="button" className="btn btn-primary" >
      Processing...
</button>
     ):( 
        <button type="submit" className="btn btn-primary" disabled={!stripe}>
          Pay & Publish
      </button>)}
      </form>
     
          )}

    </>
  )
}
