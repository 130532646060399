import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectCoolingTypeInput} from '../../SelectInput/SelectCoolingTypeInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyCoolingType = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleCoolingTypeChange = (option:any) =>{    
        setProperty({...property,coolingTypeId:option.value});
      }
      
        return (     
            <>
                   <Col md="3" sm="12">
                <label>Cooling Type</label>
                <SelectCoolingTypeInput                               
                  defaultValue={property.coolingTypeId}
                  name="coolingType"                
                  className={classnames("React",{
                    invalid: (property.coolingTypeId == undefined)
                  })}
                  onChange={handleCoolingTypeChange}
                /> 
              </Col>   
         </>
    )
}
    