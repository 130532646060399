import React,{useEffect, useState} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import Checkbox from "../../checkbox/CheckboxesVuexy"
import { Check } from "react-feather"
import {Property} from '../../../../contracts/response-models/property'

import {listAmenitiesService} from "../../../../machines/listing-machine/services"
import { Aminity } from "../../../../contracts/response-models/aminity";


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyRentFeatures = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const [amenities, setAmenities] = React.useState<Aminity[]>([]);

    useEffect(() => {    
        async function loadAmenities() {
            const amenities = await listAmenitiesService(property.propertyType,property.saleType);    
            setAmenities(amenities);              
        }
        loadAmenities();
    },[]);

    const  handleAmentiesChange = (e:any) => {  
       
       var emptyArr = new Array<Aminity>()
       var arrAmenities = property.aminities == undefined ? emptyArr : property.aminities;
       const selectedAminity:Aminity = {
            id : parseInt(e.target.value),
            title : ""
        }
       if(e.target.checked){  
           // console.log(e.target.value);
            
            arrAmenities.push(selectedAminity);
        }
        else{
          //  console.log("uncheck");            
            delete arrAmenities[selectedAminity.id];
        }
        setProperty({...property,aminities:arrAmenities});
      }

        return (     
            <>        
           <Row className="mt-2">     
           
           {amenities.map(function(object, i){
                    return (
                        <Col md="4" sm="12">
                            <div className="d-inline-block mr-1">
                            <Checkbox color="primary" value={object.id}   onChange={handleAmentiesChange} icon={<Check className="vx-icon" size={16} />} label={object.title} defaultChecked={false}/>
                       </div>
                        </Col>
                        );
            })}                     
                        
            </Row>     
         </>
    )
}
    