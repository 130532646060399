import React, { useState, useEffect } from "react";
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";

import {
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Input,
  CustomInput,
  Form,
  Button,
  Spinner
} from "reactstrap"
import Select from "react-select"
import PhoneInput from 'react-phone-input-2'

import { Post } from '../contracts/response-models/post'
import {getPostInfoService} from "../machines/listing-machine/services"
import {createPostService} from '../machines/listing-machine/services'
import {editPostService} from '../machines/listing-machine/services'


import { ValidationMessage } from "../components/@vuexy/validation-message";
import { toast, ToastContainer } from "react-toastify";

export const AddNewPost = () => {
  const [loading, setLoading] = useState(false);
  const [isSavedSuccess, setSavedSuccess] = useState(false); 
  const [post, setPost] = useState<Post>({
    id: 0, 
  });

  useEffect(() => {
    async function loadPostInfo() {
    setLoading(true);
    if (id > 0) {
      const postinfo = await getPostInfoService(id);  
      setPost(postinfo);   

    }
    setLoading(false);
  }
  loadPostInfo();

  }, []);
  let { id } = useParams();

  // Set Post Variables ////
  const handlePostTitleChange = (e:any) =>{   
    setPost({...post,title:e.target.value});
  }
  const [isUploading,setIsUploading] = useState(false);
 
  const handlePostPhotoChange = (e:any) =>{
    
    setIsUploading(true);
    const filesUpload = e.target.files;  
    const formData = new FormData()

    formData.append('files', filesUpload[0])
    uploadFiles(formData);
  }
  const uploadFiles=(formData:any)=>{
    fetch('https://api.zaglist.com/api/upload', {  
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {    
     
     //alert(data[0].fileName);     
      setPost({...post,photo:data[0].fileName});
      setIsUploading(false); 
    })
    .catch(error => {
      console.error(error)
    }) 
  }


  const handlePostDescriptionChange = (e:any) =>{   
    setPost({...post,description:e.target.value});
  }

  // Save Post Function //
  const savePost = async (e: any) => {

    e.preventDefault()
    var isValidated = true;
    if (post.title == undefined || post.title == "") {
      setPost({ ...post, title: '' });
      isValidated = false
    }
    if (post.photo == undefined || post.photo == "") {
      setPost({ ...post, photo: '' });
      isValidated = false
    }
    if (post.description == undefined || post.description == "") {
      setPost({ ...post, description: '' });
      isValidated = false
    }

    if (isValidated == true) {
      setLoading(true);
      if (id > 0) {
          const response = await editPostService(post,id); 
          toast.success("Post Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
         const response = await createPostService(post); 
          toast.success("Post Added Successfully", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      setLoading(false);
      setSavedSuccess(true);         
    }

  }



  if(isSavedSuccess)
  {
    return (<Redirect to="/posts" />)
  }
   
  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle={(id != undefined && id > 0) ? "Edit Post" : "Add New Post"} breadCrumbParent="Blog" breadCrumbActive={(id != undefined && id > 0) ? "Edit Post" : "Add New Post"} />
      <Card>
        <CardBody>    <ToastContainer />
      
          {loading ? (<div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>) : (

              <Row className="mt-1">
                <Col className="mx-auto" md="7" sm="12">
                  <h3 className="mb-2 text-left">{(id != undefined && id > 0) ? "Edit Post #" : "Add New Post"} {id} </h3>
                  <Form>
                    <FormGroup row>
                      <Col md="3">
                        <span>Post Title</span>
                      </Col>
                      <Col md="9">
                        <Input
                          type="text"
                          name="postTitle"
                          id="postTitle"
                          value={post?.title}
                          onChange={handlePostTitleChange}
                          placeholder="Post Title"
                        />
                        <ValidationMessage isInvalid={(post.title == undefined) || (post.title != '') ? false : true} errorMessage="Please enter post title" />
                      </Col>

                    </FormGroup>

                    <FormGroup row>
                      <Col md="3">
                        Upload image
                </Col>
                      <Col md="9">
                        <CustomInput
                          type="file"
                          id="postPhoto"
                          name="postPhoto"
                          
                          onChange={handlePostPhotoChange}
                        />
                         {isUploading && ("Uploading...")}  <br />
                  {(post.photo!=undefined) && (post.photo!='') && (
                    <> <img className="img-responsive" height="100"  src={post.photo}/>  
                    </>
                  )}
                        <ValidationMessage isInvalid={(post.photo == undefined) || (post.photo != '') ? false : true} errorMessage="Please upload Photo" />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Col md="3">
                        <span>Description</span>
                      </Col>
                      <Col md="9">
                        <Input
                          type="textarea"
                          name="text"
                          id="postDescription"
                          rows="3"
                          value={post?.description}
                          onChange={handlePostDescriptionChange}
                          placeholder="Description"
                        />
                        <ValidationMessage isInvalid={(post.description == undefined) || (post.description != '') ? false : true} errorMessage="Please upload Description" />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Col md={{ size: 8, offset: 4 }}>
                        <Button
                          color="primary"
                          type="submit"
                          className="mr-1 mb-1"
                          onClick={savePost}
                        >
                          Submit
                </Button>
                        <Button
                          outline
                          color="warning"
                          type="reset"
                          className="mb-1"
                        >
                          Reset
                </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>)}
        </CardBody>
      </Card>

    </React.Fragment>
  )
}