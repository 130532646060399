import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyAvailableDate = ({ property, setProperty}:PricingStepProps) => {   
    
    const handleAvailableDateChange = (e:any) =>{
       
      if(e.target.value===false)
        {
          setProperty({...property,availableDate:undefined});         
        }else{          
          setProperty({...property,availableDate:e.target.value});           
        }
       
      }
    

        return (     
            <>    
                <div className="form-label-group mt-2">
        <div className="form-label-group mt-2">Available Date</div>
                <Input           
                   placeholder="Available Date"     
                   type="date"
                  value={(property.availableDate == undefined ? "" : property.availableDate)}
                  className={classnames("mt-2",{
                    invalid: (property.availableDate == undefined || property.availableDate.toString()=="")
                  })}
                  mobile                   
                  onChange={handleAvailableDateChange}
                />
               
              </div>

                 
         </>
    )
}
    