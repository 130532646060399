import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsMFHS = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
              <Row className="mb-2 mt-2">
              MFHS
              <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              # of Rooms :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfRooms}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Bedrooms  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfBedrooms}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Full Baths  :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfFullBaths}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Half Baths  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfHalfBaths}
              </Col>
              </Row>
    
                  </Col> 
                  <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Basement :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.basementId}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Style :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.styleId}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Exterior Finish :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.exteriorFinishId}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Year Built :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.yearBuiltId}
              </Col>
              </Row>
    
                  </Col> 
                  <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Lot Size :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.lotSizeD} x {property?.lotSizeW}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Heating Source :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.heatingSourceId}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Cooling Type :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.coolingTypeId}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Garage :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.garageId}
              </Col>
              </Row>
    
                  </Col> 

                  </Row>
         </>
    )
}
    