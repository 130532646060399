import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import {PropertyType,SaleType} from '../../contracts/enums'
interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsSFHS = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
              <Row className="mb-2 mt-2">
              
              <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              # of Rooms :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfRooms}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Bedrooms  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfBedrooms}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Full Baths  :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfFullBaths}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Half Baths  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfHalfBaths}
              </Col>
              </Row>
    
                  </Col> 
                  <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Basement :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.basement}
              </Col>
             
              {(property.propertyType==PropertyType.SFH ) &&
              (
                <> 
                <Col md="6" sm="6"   className="mb-1 text-right">
                    Style :
                </Col>
                <Col md="6" sm="6"  className="font-weight-bold" > 
                    {property?.style}
                </Col>
                </>
              )
               }
              <Col md="6" sm="6"   className="mb-1 text-right">
              Exterior Finish :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.exteriorFinish}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Year Built :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.yearBuilt}
              </Col>
              </Row>
    
                  </Col> 
                  <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Lot Size :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.lotSizeD} x {property?.lotSizeW}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Heating Source :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.heatingSource}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Cooling Type :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.coolingType}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Garage :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.garage}
              </Col>
              </Row>
    
                  </Col> 

                  </Row>
         </>
    )
}
    