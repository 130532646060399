import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectBasementInput} from '../../SelectInput/SelectBasementInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyBasement = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleBasementChange = (option:any) =>{    
        setProperty({...property,basementId:option.value});
      }

        return (     
            <>
           <Col md="3" sm="12">
            <label>Basement</label>
            <SelectBasementInput                               
              defaultValue={property.basementId}
              name="basement"    
              className={classnames("React",{
                invalid: (property.basementId == undefined)
              })}
              onChange={handleBasementChange}
            />   
          </Col>
         </>
    )
}
    