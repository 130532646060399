import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsSFHR = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
              <Row className="mb-2 mt-2">
              
              <Col md="4" sm="6">
              <Row  >
             
              <Col md="6" sm="6"   className="mb-1 text-right">
              Bedrooms  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfBedrooms}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Full Baths  :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfFullBaths}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Half Baths  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfHalfBaths}
              </Col>
              <Col md="6" sm="6"   className="mb-1  text-right">
              Garage :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.garage}
              </Col>
              </Row>
    
                  </Col> 
                  <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Basement :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.basement}
              </Col>
              <Col md="6" sm="6" className="mb-1 text-right">
              Heating Source :
              </Col>
              <Col md="6" sm="6" className="font-weight-bold" > 
              {property?.heatingSource}
              </Col>
              <Col md="6" sm="6" className="mb-1 text-right">
              Cooling Type :	
              </Col>
              <Col md="6" sm="6" className="font-weight-bold" > 
              {property?.coolingType}
              </Col>
              <Col md="6" sm="6" className="mb-1 text-right">
              Year Built :
              </Col>
              <Col md="6" sm="6" className="font-weight-bold" > 
              {property?.yearBuilt}
              </Col>
              </Row>
    
                  </Col> 
                  <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Pets Allowed? :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
           
              {(property.isPetsAllowed!=undefined && property.isPetsAllowed==true) ? "Yes":"No" }
                
              </Col>             
              </Row>
    
                  </Col> 

                  </Row>
         </>
    )
}