import React, { useEffect, useState } from "react";

import { Link, Redirect } from "react-router-dom"


import { Row, Col, Button, Card, CardBody, ListGroup, ListGroupItem,
FormGroup,
Progress,
Input,
Spinner ,
UncontrolledButtonDropdown,
DropdownMenu,
DropdownItem,
DropdownToggle,
Modal,
ModalHeader,
ModalBody,
Label,
ModalFooter,
TabPane,} from "reactstrap"
//import SortIcon from "@material-ui/icons/ArrowDownward";
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";

import { PropertyStatus } from '../contracts/response-models/propertystatus'

import { propertyStatusService } from '../machines/listing-machine/services'


import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Edit,
  Trash,
  Eye,
  ChevronDown,
} from "react-feather"


import { listPropertiesService } from "../machines/listing-machine/services"
import { Property } from "../contracts/response-models/property";
import { DeletePropertyService } from "../machines/listing-machine/services"
import {PropertyOHModal} from '../components/MyListings/property-oh-modal';
import {PropertyELModal} from '../components/MyListings/property-el-modal';
import {PropertyFLModal} from '../components/MyListings/property-fl-modal';
import {PropertyCPModal} from '../components/MyListings/property-cp-modal';
import {PropertyVLModal} from '../components/MyListings/property-vl-modal';
import {PropertyEVModal} from '../components/MyListings/property-ev-modal';



import {useModal} from '../components/MyListings/useModal';

export enum ActionModalTypes{ 
  OpenHouse="1",  
  ExtendListing="2",
  FeaturedListing="3",
  ChangePrice="4", 
  VirtualLink="5",
  EmbedVideo="6",
}


export const MyListings = () => {
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = React.useState<Property[]>([]);
  const [readyState, setReadyState] = useState(true);
  const [paged, setPropertiesInfo] = useState(1);
  const [perpagearecord, setPagePerRecord] = useState(10);
  const [sortedColumn, setSortedColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('desc');

  const [propertyStatus, setpropertyStatus] = useState<PropertyStatus>(
    {
      status:"0"
    }
  );

  useEffect(() => {
    async function loadProperties() {
       if(readyState==true)  
       {      
        setLoading(true);
        const properties = await listPropertiesService(paged, perpagearecord, sortedColumn, sortDirection);
        setProperties(properties);
        setLoading(false);
        setReadyState(false);
      }  
    }

    loadProperties();

  }, [readyState, paged, perpagearecord, sortedColumn, sortDirection]);

  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showDeleteMessage, setshowDeleteMessage] = useState(false);
  const [totalrows, settotalrows] = useState(50);

  const [idToDelete, setIdToDelete] = useState(0);

  const handleRemove = (id: number) => {
    setIdToDelete(id);
    setshowDeleteConfirm(true);
  }

  const [showSuspendConfirm, setshowSuspendConfirm] = useState(false);
  const [showSuspendMessage, setshowSuspendMessage] = useState(false);
  const [idToSuspend, setIdToSuspend] = useState(0);

  const handleSuspendListing = (id: number) => {
    setIdToSuspend(id);
    setpropertyStatus({ ...propertyStatus, status:"2" });
    setshowSuspendConfirm(true);
  }

  const [showActivateConfirm, setshowActivateConfirm] = useState(false);
  const [showActivateMessage, setshowActivateMessage] = useState(false);
  const [idToActivate, setIdToActivate] = useState(0);

  const handleActivateListing = (id: number) => {
    setIdToActivate(id);
    setpropertyStatus({ ...propertyStatus, status:"1" });
    setshowActivateConfirm(true);
  }
  


  const ChangePagination = (page: any, totalRows: any) => { setPropertiesInfo(page); }
  const ChangeRowsPerPage = (rowPerPage: number) => { setPagePerRecord(rowPerPage); }
  const sortProperties = (rows: any[], field: string, direction: any) => {
    return rows.reverse();

  }

  const sortResult = (column: any, sortDirection: any) => {
    // alert(column.selector+", "+sortDirection);
    setSortedColumn(column.selector);
    setSortDirection(sortDirection);

  };

  const handleConfirmDelete = async (confirmRespons: boolean) => {
    if (!confirmRespons) {
      setshowDeleteConfirm(confirmRespons);
      return;
    }
    if (idToDelete > 0) {
      //console.log(idToDelete);
      setshowDeleteConfirm(false);
      setLoading(true);
      const rsp = await DeletePropertyService(idToDelete)
      toast.success("Property Deleted Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
      setReadyState(true);

    }
  }
  const handleDeleteMessage = (deleteMessage: boolean) => {
    setshowDeleteMessage(deleteMessage);
  }

  const handleConfirmSuspend = async (confirmRespons: boolean) => {
    if (!confirmRespons) {
      setshowSuspendConfirm(confirmRespons);
      return;
    }
    if (idToSuspend > 0) {      
      setshowSuspendConfirm(false);
      setLoading(true);     
      setpropertyStatus({ ...propertyStatus, status:"2" });
        
      const rsp = await propertyStatusService(propertyStatus, idToSuspend);      
      toast.success("Property Suspended Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
      setReadyState(true);

    }
  }
  const handleSuspendMessage = (suspendMessage: boolean) => {
    setshowSuspendMessage(suspendMessage);
  }

  const handleConfirmActivate = async (confirmRespons: boolean) => {
    if (!confirmRespons) {
      setshowActivateConfirm(confirmRespons);
      return;
    }
    if (idToActivate > 0) {      
      setshowActivateConfirm(false);
      setLoading(true);     
      const rsp = await propertyStatusService(propertyStatus, idToActivate);     
      toast.success("Property Activated Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
      setLoading(false);
      setReadyState(true);

    }
  }
  const handleActivateMessage = (activateMessage: boolean) => {
    setshowActivateMessage(activateMessage);
  }



const { isModalOpened, toggleModal } = useModal();
const [openModal, setOpenModal] = useState<ActionModalTypes>();

const[selectedProperty,setSelectedProperty] = useState<Property>(); 

const openActionModal = (property:Property,modalType:ActionModalTypes) => {
  setOpenModal(modalType);
  setSelectedProperty(property);  
  toggleModal();    
}

  const columns = [
    {
      name: "ZAG#",
      selector: "id",
      sortable: true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
         
          <Link to={`/listings/preview/${row.id}`} className="cursor-pointer text-center">
          {row.id}
          </Link>
        </p>
      )
    },
    {
      name: "ADDRESS",
      selector: "street",
      sortable: true
    },
    {
      name: "PUBLIC",
      selector: "isAddressPublic",
      sortable: true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.isAddressPublic && ("Yes")}
          {!row.isAddressPublic && ("No")}        
        </p>
      )
    },
    {
      name: "LISTING DATE",
      selector: "startDate",
      sortable: true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.startDate}
        </p>
      )
    },
    {
      name: "EXPIRATION",
      selector: "endDate",
      sortable: true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.endDate}
        </p>
      )
    },
    {
      name: "STATUS",
      selector: "status",
      sortable: true,
      cell: (row: any) => (
        <p className="text-truncate  mb-0">
          {row.status == 3 && ("Expired")}
          {row.status == 2 && ("Suspended")}
          {row.status == 1 && ("Active")}
          {row.status == 0 && ("Pending")}

        </p>
      )
    },
    {
      name: "ACTIONS",
      selector: "",
      sortable: false,
      cell: (row: any) => (<>
         <UncontrolledButtonDropdown>
                        <DropdownToggle color="primary" size="sm" caret>
                          Action
                          <ChevronDown size={15} />
                         </DropdownToggle>
                        <DropdownMenu >
                      {row.status==0 ? (<>
                        <Link to={`/listings/add/${row.id}`} className="cursor-pointer">
                          <DropdownItem  tag="li" >Edit
                          </DropdownItem></Link>
                        <Link to={`/listings/publish/${row.id}`} className="cursor-pointer"  >
              <DropdownItem    tag="li"  >Publish 
             </DropdownItem>
          </Link>
                       <Link to={`/listings/`} className="cursor-pointer"  >
              <DropdownItem  onClick={() => handleRemove(row.id)} tag="li"  >Delete
             </DropdownItem>
          </Link></>):(<> 
                          <Link to={`/listings/add/${row.id}`} className="cursor-pointer">
                          <DropdownItem  tag="li" >Edit
                          </DropdownItem></Link>
            <Link to={`/listings/`} className="cursor-pointer"  >
              <DropdownItem  onClick={() => handleRemove(row.id)} tag="li"  >Delete
             </DropdownItem>
          </Link>
          {row.status == 1 && ( 
          <Link to={`/listings/`} className="cursor-pointer"  >
          <DropdownItem tag="li" className="cursor-pointer" onClick={() => handleSuspendListing(row.id)}  >
             Suspend Listing</DropdownItem></Link>
          )}
           {row.status == 0 || row.status == 2  || row.status == 3 ? ( 
             <Link to={`/listings/`} className="cursor-pointer"  >
             <DropdownItem tag="li" className="cursor-pointer" onClick={() =>  handleActivateListing(row.id)}  >
             Activate Listing</DropdownItem></Link>
           ):( <></>)}
          <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row,ActionModalTypes.OpenHouse)}  >
            Open House</DropdownItem>            
          <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row,ActionModalTypes.ExtendListing)}  >
             Extend Listing</DropdownItem>
             <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row,ActionModalTypes.FeaturedListing)}  >
             Featured Listing</DropdownItem>
             <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row,ActionModalTypes.ChangePrice)}  >
             Change Price</DropdownItem>
      
             <DropdownItem tag="li" className="cursor-pointer" onClick={() => openActionModal(row,ActionModalTypes.VirtualLink)}  >
             Virtual Tour Link</DropdownItem>
             <DropdownItem tag="li" className="cursor-pointer " onClick={() => openActionModal(row,ActionModalTypes.EmbedVideo)}  >
             Embed Video</DropdownItem>
             </> )}  
  </DropdownMenu>
                      </UncontrolledButtonDropdown>
        <div className="data-list-action d-none">
          <Link to={`/listings/preview/${row.id}`} className="cursor-pointer">
            <Eye className="cursor-pointer mr-1" size={20} />
          </Link>
          <Link to={`/listings/add/${row.id}`} className="cursor-pointer">
            <Edit className="cursor-pointer mr-1" size={20} />
          </Link>
          <Link to={`/listings/`} className="cursor-pointer"  >
            <Trash
              className="cursor-pointer"
              size={20}
              onClick={() => handleRemove(row.id)}
            />
          </Link>
        </div>
        </>
      )
    },

  ];


  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle="My Listings" breadCrumbParent="Listings" breadCrumbActive="My Listings" />
      <ToastContainer />

      <Card className="mt-2">
        <CardBody>
          {loading ? (
            <div className="d-flex justify-content-between mt-1">                               
            <Spinner size="lg"  className="primary float-right"/>
          </div>
          ):(

         
          <DataTable
           noHeader={true}
            columns={columns}
            data={properties}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination={false}
            onChangePage={ChangePagination}
            onChangeRowsPerPage={ChangeRowsPerPage}
            paginationTotalRows={totalrows}
            paginationPerPage={perpagearecord}
            paginationServer={true}
            selectableRows={false}
            responsive={true}
            sortServer={true}
            onSort={sortResult}            
            striped={true}
          />
          )}
        </CardBody>

      </Card>

      <SweetAlert title="Are you sure?"
        warning
        show={showDeleteConfirm}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        onConfirm={() => handleConfirmDelete(true)}
        onCancel={() => handleConfirmDelete(false)}
      >
        You won't be able to revert this!
        </SweetAlert>
      <SweetAlert success title="Deleted!"
        confirmBtnBsStyle="success"
        show={showDeleteMessage}
        onConfirm={() => handleDeleteMessage(false)}
      >
        <p className="sweet-alert-text">Your property deleted.</p>
      </SweetAlert>

      <SweetAlert title="Are you sure?"
        warning
        show={showActivateConfirm}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, Activate it!"
        cancelBtnText="Cancel"
        onConfirm={() => handleConfirmActivate(true)}
        onCancel={() => handleConfirmActivate(false)}
      >
        
        </SweetAlert>
      <SweetAlert success title="Activated!"
        confirmBtnBsStyle="success"
        show={showActivateMessage}
        onConfirm={() => handleActivateMessage(false)}
      >
        <p className="sweet-alert-text">Your property activated successfully.</p>
      </SweetAlert>

      <SweetAlert title="Are you sure?"
        warning
        show={showSuspendConfirm}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, Suspend it!"
        cancelBtnText="Cancel"
        onConfirm={() => handleConfirmSuspend(true)}
        onCancel={() => handleConfirmSuspend(false)}
      >        
        </SweetAlert>
      <SweetAlert success title="Suspended!"
        confirmBtnBsStyle="success"
        show={showSuspendMessage}
        onConfirm={() => handleSuspendMessage(false)}
      >
        <p className="sweet-alert-text">Your property suspended successfully.</p>
      </SweetAlert>

      <Modal
              id="actionModal"
                  isOpen={isModalOpened}
                  toggle={toggleModal}
                  className="modal-dialog-centered"
                >
      {openModal == ActionModalTypes.OpenHouse && (
        <PropertyOHModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState}  />
      )} 
       {openModal == ActionModalTypes.ExtendListing && (
        <PropertyELModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
      )}  
      {openModal == ActionModalTypes.FeaturedListing && (
        <PropertyFLModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
      )} 
      {openModal == ActionModalTypes.ChangePrice && (
        <PropertyCPModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
      )}
      
      {openModal == ActionModalTypes.VirtualLink && (
        <PropertyVLModal property={selectedProperty} toggle={toggleModal} readyState={readyState} setReadyState={setReadyState} />
      )} 
      {openModal == ActionModalTypes.EmbedVideo && (
        <PropertyEVModal property={selectedProperty}   toggle={toggleModal} readyState={readyState} setReadyState={setReadyState}  />
      )} 
      
      </Modal>  
    </React.Fragment>
  )
}