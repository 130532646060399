import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import { useParams } from "react-router";
import { Edit, Trash, Lock, Check } from "react-feather"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import { getPostInfoService } from "../machines/listing-machine/services"
import { Post } from '../contracts/response-models/post'
import "./custom.css";

import {
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Input,
  Media,
  Table,
  CustomInput,
  Form,
  Button,
  Spinner
} from "reactstrap"
import userImg from "../assets/img/portrait/small/avatar-s-18.jpg"
import "../assets/scss/pages/users.scss"
export const PostPreview = () => {
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState<Post>({
    id: 0,
    title: "",
    description: ""
  });
  useEffect(() => {
    async function loadPostInfo() {
      setLoading(true);
      if (id > 0) {
        const postinfo = await getPostInfoService(id);
        setPost(postinfo);

      }
      setLoading(false);
    }
    loadPostInfo();

  }, []);
  let { id } = useParams();

  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle="Post Preview" breadCrumbParent="Post" breadCrumbActive="Post Preview" />
      <Card>

        <CardHeader>
          <CardTitle>Post Preview</CardTitle>
        </CardHeader>
        <CardBody>
          {loading ? (<div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>) : (
              <Row className="mx-0" col="12">
                <Col className="pl-0" sm="12">
                  <Media className="d-sm-flex d-block">
                    <Media className="mt-md-1 mt-0" left>
                      <Media
                        className="rounded mr-2"
                        object
                        src={post.photo}
                        alt="User Image"
                        height="112"
                        width="112"
                      />
                    </Media>
                    <Media body>
                      <Row className="ml-5">
                        <Col sm="12" md="12" lg="12">
                          <div className="users-page-view-table">
                            <div className="d-flex user-info font-weight-bold">
                              <div><h3>{post.title}</h3></div>

                            </div>

                          </div>
                        </Col>
                        <Col md="12" lg="5">

                        </Col>
                      </Row>
                      <Row className="ml-5">
                        <Col sm="12" md="12" lg="12" className="nl2brClass">
                          {post.description}

                        </Col>
                        <Col md="12" lg="5">

                        </Col>
                      </Row>
                    </Media>
                  </Media>
                </Col>

                <Col className="mt-1 pl-0" sm="12">
                  <Button className="mr-1" color="primary" outline>
                    <Link to={`/posts/add/${post.id}`}>
                      <Edit size={15} />
                      <span className="align-middle ml-50">Edit</span>
                    </Link>
                  </Button>
                </Col>
              </Row>)}
        </CardBody>


      </Card>
    </React.Fragment>
  )
}
export default PostPreview;