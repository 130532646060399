import { trimLeadingSlash, trimTrailingSlash } from "../utils";

export const localStorageKeyToken = "__zaglist_token__";
export const localStorageKeyBaseUri = "__zaglist_uri__";

interface Headers {
    [x: string]: string;
}

export const client = async <TResponse>(
    endpoint: string,
    body?: any,
    customConfig?: RequestInit
    ): Promise<TResponse> => {
    const baseUri = 'https://api.zaglist.com/api'//window.localStorage.getItem(localStorageKeyBaseUri);
    const token = window.localStorage.getItem(localStorageKeyToken);

    if (!baseUri) {
        throw new Error("Base URI isn't specified.");
    }

    // await sleep(2000);

    return consume(baseUri, endpoint, token, body, customConfig);
    };

    export const consume = <TResponse>(
    baseUri: string,
    endpoint: string,
    token?: any,
    body?: any,
    customConfig?: RequestInit
    ): Promise<TResponse> => {
    const headers: Headers = { "content-type": "application/json" };

    if (!baseUri) {
        throw new Error("Base URI isn't specified.");
    }

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const config: RequestInit = {
        method: body ? "POST" : "GET",
        ...customConfig,
        headers: {
        ...headers,
        ...customConfig?.headers,
        },
    };
    if (body) {
        config.body = JSON.stringify(body);
    }
    return window.fetch(uri(baseUri, endpoint), config).then(async (response) => {
        let data;

        try {
        data = await response.json();
        } catch {
        // TODO: Change API client logic
        data = null;
        }

        if (response.ok) {
        return data as TResponse;
        } else {
        return data
            ? Promise.reject<TResponse>(data)
            : Promise.reject<TResponse>(
                response.statusText || `Unexpected error code ${response.status}`
            );
        }
    });
    };

    export function getTokenFromLocalStorage() {
    const baseUri = window.localStorage.getItem(localStorageKeyBaseUri);
    const token = window.localStorage.getItem(localStorageKeyToken);

    if (!baseUri || !token) {
        return null;
    }

    return { baseUri, token };
}
  
export function storeTokenInLocalStorage(baseUri: string, token: string) {
    window.localStorage.setItem(localStorageKeyBaseUri, baseUri);
    window.localStorage.setItem(localStorageKeyToken, token);
}
  
function uri(baseUri: string, endpoint: string) {
    return `${trimTrailingSlash(baseUri)}/${trimLeadingSlash(endpoint)}`;
}
  
export function endpoint(endpoint: string) {
    const baseUri = localStorage.getItem(localStorageKeyBaseUri);

    if (!baseUri) {
        throw new Error("Base URI isn't specified.");
    }

    return uri(baseUri, endpoint);
}