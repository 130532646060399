import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import {SelectConditionInput} from '../../SelectInput/SelectConditionInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyUnitDetails = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleUnitDetailsChange = (value:any) =>{
        setProperty({...property,noOfBedrooms:value});
      }   
      //var units = ['Jake', 'Jon', 'Thruster'];
        return (     
            <>  
                     
           
                {Array(property.units).fill(0,0,property.units).map(function(object, i){
                    return (
                       
                     <>
                    <Row><Col md="12" sm="12">Unit {i+1}</Col></Row>
            <Row>
                  <Col md="3" sm="12">
                <div className="d-inline mr-1">  Bedrooms</div>
                <NumericInput 
                  min={0} 
                  max={99} 
                  value={property.noOfBedrooms}
                  className={classnames("ml-1",{
                    invalid: (property.noOfBedrooms == undefined)
                  })}
                  mobile 
                  style={mobileStyle} 
                  onChange={handleUnitDetailsChange}
                />
            </Col> 

            <Col md="3" sm="12">
                <div className="d-inline mr-1">Full Baths</div>
                <NumericInput 
                  min={0} 
                  max={99} 
                  value={property.noOfFullBaths} 
                  className={classnames("ml-1",{
                    invalid: (property.noOfFullBaths == undefined)
                  })}
                  mobile 
                  style={mobileStyle} 
                  onChange={handleUnitDetailsChange}
                />
            </Col>  

            <Col md="3" sm="12">
                <div className="d-inline mr-1">Half Baths</div>
                <NumericInput 
                  min={0} 
                  max={99} 
                  value={property.noOfHalfBaths}
                  className={classnames("ml-1",{
                    invalid: (property.noOfHalfBaths == undefined)
                  })}
                  mobile 
                  style={mobileStyle} 
                  onChange={handleUnitDetailsChange}
                />
            </Col>    
            <Col md="3" sm="12">
                <label>Condition</label>
                <SelectConditionInput                               
                  defaultValue={property.conditionId}
                  name="condition"                
                  className={classnames("React",{
                    invalid: (property.conditionId == undefined)
                  })}
                  onChange={handleUnitDetailsChange}
                />
              </Col> 
            </Row>   
            </>        
              );
            })}            

         </>
    )
    
}
    