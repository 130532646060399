import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyFullBath = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleFullBathsChange = (value:any) =>{
        setProperty({...property,noOfFullBaths:value});
      }
    

        return (     
            <>         
                    <Col md="3" sm="12">
                <div className="d-inline mr-1">Full Baths</div>
                <NumericInput 
                  min={0} 
                  max={99} 
                  value={property.noOfFullBaths} 
                  className={classnames("ml-1",{
                    invalid: (property.noOfFullBaths == undefined)
                  })}
                  mobile 
                  style={mobileStyle} 
                  onChange={handleFullBathsChange}
                />
            </Col> 
         </>
    )
}
    