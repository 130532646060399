import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsLND = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
              <Row className="mb-2 mt-2">
              
              <Col md="6" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Acres :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
        {property?.availavleAcres} ({property?.lotSizeD} x {property?.lotSizeW})
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Topography   :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.topography}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Wetlands  :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.wetLand}
              </Col>
             
              </Row>
    
                  </Col> 
                  <Col md="6" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Total Acres :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.totalAcres}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Smallest Acres :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.smallestAcres}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              # of Lots :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noofLots}
              </Col>
           
              </Row>
    
                  </Col> 
   

                  </Row>
         </>
    )
}
    