import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectHSourceInput} from '../../SelectInput/SelectHSourceInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyHeatingSource = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleHeatingSourceChange = (option:any) =>{    
        setProperty({...property,heatingSourceId:option.value});
      }
    

        return (     
            <>  
           
           <Col md="3" sm="12">
            <label>Heating Source </label>
            <SelectHSourceInput                               
              defaultValue={property.heatingSourceId}
              name="heatingSource"                
              className={classnames("React",{
                invalid: (property.heatingSourceId == undefined)
              })}
              onChange={handleHeatingSourceChange}
            /> 
          </Col>
         </>
    )
}
    