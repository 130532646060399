import React, { useEffect, useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  FormGroup,
  ModalHeader,
  ModalBody,
  Button,
  Spinner,

} from "reactstrap"

import { Property } from '../../contracts/response-models/property'

interface PropertyIdProps {
  property?: Property,  
}


export const PropertyHeadInfo = ({ property }: PropertyIdProps) => {

  return (
  
            <>

            
              ZAG# {property?.id}
              <FormGroup>
                <Label for="address">{property?.street}, {property?.areaTitle}, {property?.stateCD}  {property?.zipCode}</Label>

              </FormGroup>

    </>
  )
}
