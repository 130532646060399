import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import {PropertyType,SaleType} from '../../../contracts/enums'
import {Property} from '../../../contracts/response-models/property'


import {PropertyListPrice} from "./listingfields/property-listprice"
import {PropertyAnnualTax} from "./listingfields/property-annualtax"
import {PropertyCommonCharges} from "./listingfields/property-commoncharges"
import {PropertyStartDate} from "./listingfields/property-startDate"
import {PropertyExpiryDate} from "./listingfields/property-enddate"

interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyPriceSale = ({ property, setProperty}:PricingStepProps) => {      

        return (     
                <>

                <PropertyListPrice property={property} setProperty={setProperty}/>
                <PropertyAnnualTax property={property} setProperty={setProperty}/>
                
                { property.propertyType==PropertyType.APT  &&
              (     
                <PropertyCommonCharges property={property} setProperty={setProperty}/>
              )}        
              <PropertyStartDate property={property} setProperty={setProperty}/>
              <PropertyExpiryDate property={property} setProperty={setProperty}/>
            </> 
    )
}
    