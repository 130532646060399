import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,  
    navLink: "/dashboard"
  },
  {
    type: "groupHeader",
    groupTitle: "Profile"
  },
  {
    id: "previewProfile",
    title: "Public Profile",
    type: "item",
    icon: <Icon.User size={20} />,    
    navLink: "/preview-profile",
    filterBase: "/preview-profile"  
  },  
  {
    id: "editProfile",
    title: "Edit Profile",
    type: "item",
    icon: <Icon.Edit size={20} />,    
    navLink: "/edit-profile",    
    filterBase: "/edit-profile"  
  },   
  
  {
    type: "groupHeader",
    groupTitle: "Listings"
  },
  {
    id: "addListing",
    title: "Add New Listing",
    type: "item",
    icon: <Icon.PlusSquare size={20} />,   
    navLink: "/listings/add",
    filterBase: "/listings/add"  
  },
  {
    id: "myListings",
    title: "My Listings",
    type: "item",
    icon: <Icon.List size={20} />,   
    navLink: "/listings" ,
    filterBase: "/listings"  
  },          
  {
    type: "groupHeader",
    groupTitle: "Blog"
  },
  {
    id: "addPost",
    title: "Add New Post",
    type: "item",
    icon: <Icon.FileText size={20} />,   
    navLink: "/posts/add" ,
    filterBase: "/posts/add"  
  },
  {
    id: "myPost",
    title: "My Posts",
    type: "item",
    icon: <Icon.List size={20} />,
    navLink: "/posts" ,
    filterBase: "/posts"  
  },     
  {
    type: "groupHeader",
    groupTitle: "Payments"
  },
  {
    id: "myInvoices",
    title: "Invoices",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    navLink: "/invoices" ,
    filterBase: "/invoices"  
  },      
   
  {
    type: "groupHeader",
    groupTitle: "SUPPORT"
  },  
  {
    id: "raiseSupport",
    title: "Contact Support",
    icon: <Icon.LifeBuoy size={20} />,
    type: "external-link",
    newTab: true,
    permissions: ["admin", "editor"],
    navLink: "https://www.zaglist.com"
  }
]

export default navigationConfig
