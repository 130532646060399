import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectTopographyInput} from '../../SelectInput/SelectTopographyInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyTopography = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleTopographyChange = (option:any) =>{    
        setProperty({...property,topographyId:option.value});
      }
    

        return (     
            <>  
           
           <Col md="3" sm="12">
            <label>Topography</label>
            <SelectTopographyInput                               
              defaultValue={property.topographyId}
              name="topographyId"                
              className={classnames("React",{
                invalid: (property.topographyId == undefined)
              })}
              onChange={handleTopographyChange}
            /> 
          </Col>
         </>
    )
}
    