import React,{useState,useEffect} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,
   
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import { ValidationMessage } from "../@vuexy/validation-message";
import Select from "react-select/src/Select";
import { PropertyHeadInfo } from "./property-head-info-modal";

import { ChangePrice } from '../../contracts/response-models/changeprice'
import { ToastContainer, toast } from "react-toastify";
import { saveChangePriceService } from '../../machines/listing-machine/services'
import { setDatePickerFormat } from "../../utils/date-helper";

interface PropertyIdProps {  
    property?:Property,
    toggle:any; 
    readyState:any;
    setReadyState:any;
}


export const PropertyCPModal = ({ property,toggle,readyState,setReadyState}:PropertyIdProps) => {  
  const [loading, setLoading] = useState(false);

  const [changePrice, setChangePrice] = useState<ChangePrice>(
    {
      listPrice:undefined,
    }
 
  );
  const handleNewListPriceChange = (e: any) => {
    if(isNaN(e.target.value)!==false)
    {
      setChangePrice({...changePrice,listPrice:undefined});         
    }else{          
      setChangePrice({...changePrice,listPrice:e.target.value});         
    }
  }
  const handleChangedOnChange = (e: any) => {
    setChangePrice({ ...changePrice, changedOn: e.target.value });
  }
  const saveListingPrice = async (e: any) => {
    e.preventDefault()
    var isValidated = true;

    if (changePrice.listPrice == undefined || changePrice.listPrice.toString() == "") {
      setChangePrice({ ...changePrice, listPrice: undefined });
      isValidated = false
    }

    if (changePrice.changedOn == undefined || changePrice.changedOn == "") {
      setChangePrice({ ...changePrice, changedOn: '' });
      isValidated = false
    }
 

    if (isValidated == true) {
      setLoading(true);

      const response = await saveChangePriceService(changePrice, property?.id);
      toast.success("Listing Price Changed Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })

      setLoading(false);
      toggle();
      setReadyState(true);

  }
}
        return (     
          <>   
          <ModalHeader toggle={toggle}  >
            Change Listing Price 
          </ModalHeader>
        
      <ModalBody>
        {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
            <>
             <PropertyHeadInfo property={property}    />

              <FormGroup>
                <Label for="listingPrice">Original  Listing Price : </Label>
             <strong> $ {property?.originalListPrice}</strong>

              </FormGroup>
              <FormGroup>
                <Label for="listingPrice">Current Listing Price : </Label>
                <strong>   $ {property?.listPrice}</strong>

              </FormGroup>

              <FormGroup>
                <Label for="address">New Price</Label>
                <Input
                  type="text"
                  id="new_price"
                  placeholder="New Price"
                  value={(changePrice.listPrice == undefined ? "" : changePrice.listPrice)}
                  onChange={handleNewListPriceChange}
                />
 <ValidationMessage isInvalid={(changePrice.listPrice == undefined) || (changePrice.listPrice.toString() == '') ? false : true} errorMessage="Please select new price" />

              </FormGroup>
              <FormGroup>
                <Label for="address">Date</Label>
                <Input
                      type="date"
                      id="date"
                      placeholder="Date"
                      value={(changePrice.changedOn == undefined ? "" : setDatePickerFormat(changePrice.changedOn))}
                      onChange={handleChangedOnChange}
                    />
   <ValidationMessage isInvalid={(changePrice.changedOn == undefined) || (changePrice.changedOn != '') ? false : true} errorMessage="Please select changed date" />

              </FormGroup>
              <FormGroup>

                <Row className="mb-2 mt-0">
                  <Col md="4" sm="6" className="text-right">

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1 mb-1"
                      onClick={saveListingPrice}

                    >
                      Save
                </Button>
                  </Col>
                  <Col md="4" sm="6" className="text-right">
                  </Col>
                </Row>

              </FormGroup>
            </>
          )}
      </ModalBody>       
         </>
    )
}
    