
import React, { useEffect,useState } from "react";
import {
    Label,
    Row,
    Col,   
    Input,  
    FormGroup
  } from "reactstrap"
 
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import stripelogo from "../../assets/img/logo/powered_by_stripe.png"
 
interface paymentProps {
  error: string;
}
export const StripePayment = ({ error }: paymentProps) => {    
  
    return (  
        <FormGroup className="payment-info-container">
        <Label for="nameVertical">  </Label>
        <CardElement options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }} />
        {error && (
          <>
          <div className="invalid error">{error}</div>
          </>
        )}
        
        <div className="strip-logo-container" ><img src={stripelogo} /><br className="clear" /></div>

     
      </FormGroup>  
    )
}