import React, { useEffect, useState } from "react";
import { Navbar } from "reactstrap"
import { User } from "../../../contracts/response-models/user";
import { getUserInfoService } from "../../../machines/listing-machine/services";
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
interface propsType{
  sidebarVisibility:any;
  handleAppOverlay:any;
  toggleSidebarMenu:any;
}

export function TopNavBar(props:propsType) {
  const [user, setUser] = useState<User>({userId:0});
  useEffect(() => {    
    async function loadUser() { 
       const userinfo = await getUserInfoService(); 
       if(userinfo)
       { 
          if(userinfo.cellno==null)
          {
            window.location.href="/complete-profile";
          }else
          {
            setUser(userinfo);
          }
      }
    }
    loadUser();
  },[]);
  
    return(
        <React.Fragment>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <Navbar className="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow"  >
          <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="navbar-collapse d-flex justify-content-between align-items-center" id="navbar-mobile">
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div> 
              {user.firstName!=undefined && (     
              <NavbarUser userImg='' userName={user.firstName}/>
              )}
            </div>
          </div>
        </div>
        </Navbar>
      </React.Fragment>
    )
}

export default TopNavBar
  