import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyLots = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const  handleLotsChange = (value:any) => {        
        setProperty({...property,noofLots:value});
      }
      

        return (     
            <>
                                          <Col md="3" sm="12">
                <div className="d-inline mr-1">  # of Lots</div>                
                <NumericInput min={1} 
                 max={1000}     value={property.noofLots} mobile onChange={handleLotsChange} style={mobileStyle} />
            </Col> 

          
         </>
    )
}
    