import React from 'react';
import { useRouterMachine } from 'use-router-machine'
import { matchesState } from 'xstate'
import { history } from "./history"




import { Router, Switch, Route,Redirect } from "react-router-dom"
import Routes from "./routes";

import logo from './logo.svg';
import './App.css';

import {LoginPage} from './pages/LoginPage'
import {RegisterPage} from './pages/RegisterPage'
import {DashboardPage} from './pages/DashboardPage'
import {ProfilePreview} from './pages/ProfilePreview'
import {ProfileEdit} from './pages/ProfileEdit'
import {AddNewList} from './pages/AddNewList'
import {MyListings} from './pages/MyListings'
import {AddNewPost} from './pages/AddNewPost'
import {MyPosts} from './pages/MyPosts'
import {Invoices} from './pages/Invoices'

import VerticalLayout from './components/layouts/VerticalLayout'
import { format } from 'util';

function App() {
 
  
  
  return(
    <Router history={history}>
      <Routes />
    </Router>
  )
  
 /* return (
    <Router history={history}>
      <Switch>
        <Route exact path="/register" component={RegisterPage}/>
        <Route exact path="/login" component={LoginPage}/>     
        <VerticalLayout>
        <Route exact path="/dashboard" component={DashboardPage}/> 
        <Route exact path="/preview-profile" component={ProfilePreview}/> 
        <Route exact path="/edit-profile" component={ProfileEdit}/> 
        <Route exact path="/listings/add" component={AddNewList}/>                             
        <Route exact path="/listings" component={MyListings}/>                             
        <Route exact path="/posts/add" component={AddNewPost}/>                             
        <Route exact path="/posts" component={MyPosts}/>       
        <Route exact path="/invoices" component={Invoices}/>       
        </VerticalLayout>        
      </Switch>
    </Router>   
  )*/
}

export default App;

