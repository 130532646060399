import React from "react";
import { Switch } from "react-router-dom";

import { useMachine } from "@xstate/react";
import {EventData,State} from 'xstate';

import { loginMachine } from "../machines/login/machine";
import {Events as LoginEvents} from "../machines/login/events"
import {Context as LoginContext} from "../machines/login/state-context";

import PublicRoute from  "./public-route";
import PrivateRoute from  "./private-route";

import {LoginPage} from '../pages/LoginPage'
import {RegisterPage} from '../pages/RegisterPage'
import {DashboardPage} from '../pages/DashboardPage'
import {ProfilePreview} from '../pages/ProfilePreview'
import {ProfileEdit} from '../pages/ProfileEdit'
import {AddNewList} from '../pages/AddNewList'
import {MyListings} from '../pages/MyListings'
import {AddNewPost} from '../pages/AddNewPost'
import {PropertyPreview} from '../pages/PropertyPreview'
import {PostPreview} from '../pages/PostPreview'
import {MyPosts} from '../pages/MyPosts'
import {Invoices} from '../pages/Invoices'
import {InvoicePreview} from '../pages/InvoicePreview'
import {PropertyPublish} from '../pages/PropertyPublish'

import VerticalLayout from '../components/layouts/VerticalLayout'
import CompleteProfilePage from "../pages/CompleteProfilePage";


interface IProps { 
}

export default function Routes({}:IProps) {  

  const [stateLogin, sendLogin] = useMachine<LoginContext, LoginEvents>(loginMachine, {
      devTools: process.env.NODE_ENV === "development",
  });

  return (
    <Switch>      
        <PublicRoute path="/register" exact component={RegisterPage} />
        <PublicRoute path="/login" exact component={LoginPage} state={stateLogin} send={sendLogin} />
        <PrivateRoute  path="/complete-profile" exact component={CompleteProfilePage} />
        <VerticalLayout>
            <PrivateRoute exact path="/dashboard" component={DashboardPage}/> 
            <PrivateRoute exact path="/preview-profile" component={ProfilePreview}/> 
            <PrivateRoute exact path="/edit-profile" component={ProfileEdit}/> 
            <PrivateRoute exact path="/listings/add" component={AddNewList}/>  
            <PrivateRoute exact path="/listings/add/:id" component={AddNewList}/>  
            <PrivateRoute exact path="/listings/preview/:id" component={PropertyPreview}/>                                                      
            <PrivateRoute exact path="/listings" component={MyListings}/> 
            <PrivateRoute exact path="/listings/publish/:id" component={PropertyPublish}/> 
                                        
            <PrivateRoute exact path="/posts/add" component={AddNewPost}/>
            <PrivateRoute exact path="/posts/add/:id" component={AddNewPost}/>  
            <PrivateRoute exact path="/posts/preview/:id" component={PostPreview}/>                              
            <PrivateRoute exact path="/posts" component={MyPosts}/>       
            <PrivateRoute exact path="/invoices" component={Invoices}/>  
            <PrivateRoute exact path="/invoice/preview/:id" component={InvoicePreview}/>       
       </VerticalLayout>

    </Switch>
  );
}
