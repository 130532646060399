import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'
import { setDatePickerFormat } from "../../../../utils/date-helper";

interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyExpiryDate = ({ property, setProperty}:PricingStepProps) => {   
    
    const handleEndDateChange = (e:any) =>{
       
      if(e.target.value===false)
        {
          setProperty({...property,endDate:undefined});         
        }else{          
        //  const dt = new Date(e.target.value);
         // const dtDate = new Date(dt.toDateString())
         // console.log(dtDate);
          setProperty({...property,endDate:e.target.value});           
        }
       
      }
    
        return (     
            <>    
                <div className="form-label-group mt-2">
        <div className="form-label-group mt-2">Expiration </div>
        {(property.id != undefined &&  property.id >0 ? (
         
                 <Input           
                   placeholder="Expiry Date"  
                 readOnly
                   type="date"
                  value={(property.endDate == undefined ? "" : setDatePickerFormat(property.endDate))}
                  className={classnames("mt-2",{
                    invalid: (property.endDate == undefined || property.endDate.toString()=="")
                  })}
                  mobile                   
                  
                />
         
        ) : (
          <Input           
          placeholder="Expiry Date"  
        
          type="date"
         value={(property.endDate == undefined ? "" : setDatePickerFormat(property.endDate))}
         className={classnames("mt-2",{
           invalid: (property.endDate == undefined || property.endDate.toString()=="")
         })}
         mobile                   
         onChange={handleEndDateChange}
       />

        ))}   
              
               
              </div>

                 
         </>
    )
}
    