import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectBuildingTypeInput} from '../../SelectInput/SelectBuildingTypeInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyBuildingType = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleBuildingTypeChange = (option:any) =>{    
        setProperty({...property,buildingTypeId:option.value});
      }

        return (     
            <>
            <Col md="3" sm="12">
                <label>Building Type</label>
                <SelectBuildingTypeInput                               
                  defaultValue={property.buildingTypeId}
                  name="condition"                
                  className={classnames("React",{
                    invalid: (property.buildingTypeId == undefined)
                  })}
                  onChange={handleBuildingTypeChange}
                />
              </Col> 
         </>
    )
}
    