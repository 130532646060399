import React,{ useState,useEffect } from "react";
import {    
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
  } from "reactstrap"

import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import GoogleMaps from "../map/GoogleMaps"
import GoogleMapDrag from "../map/GoogleMapDrag"

interface modalProps{
    confirmModal:any,
    toogleConfirmModal:any,
    address:string,
    setLatlng:any    
}
const AnyReactComponent = ({text}: any) => <div>{text}</div>;

export const ConfirmAddressModal = ({confirmModal,toogleConfirmModal,address,setLatlng}:modalProps) => {   
    
    const [foundLocation, setFoundLocation] = React.useState({Latitude:"",Longitude:""});   
    const [isAddressFound, setAddressFound] = React.useState(false);
    const [addressError, setAddressError] = React.useState('');

    useEffect(() => {   
        Geocode.setApiKey("AIzaSyBcaGEx6WhDgqShLtzSihaiZ20Y70noRRU");
        // Get latitude & longitude from address.
        Geocode.fromAddress(address).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            setLatlng({Latitude:lat,Longitude:lng}) 
            setFoundLocation({Latitude:lat,Longitude:lng}) 
            setAddressFound(true)           
          },
          error => {
            console.error(error);
            setAddressError(error)
          }
        );
    },[address])

    return (    
          <Modal isOpen={confirmModal} toggle={toogleConfirmModal} centered  size="lg" >
          <ModalHeader toggle={toogleConfirmModal} className="bg-secondary">
            Confirm Address
          </ModalHeader>
          <ModalBody>  
            <h3 className="mb-1">Please drag marker to correct location</h3> 
          <div style={{height:'400px'}}>
          <div style={{height:'400px', width:'100%' }}>
           {isAddressFound && <GoogleMapDrag setLatlng={setLatlng} lat={foundLocation.Latitude} lng={foundLocation.Longitude}/>}                
        </div>
          </div>      
            
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toogleConfirmModal}>
                Confirm
              </Button>{" "}
            </ModalFooter>
            </Modal>
    )   
}

