import React from "react";
import Select from "react-select"

const dataOptions = [
    { value: undefined, label: "Select One"},
    { value: 1, label: "Colonial"},
    { value: 2, label: "Cape Cod"},
    { value: 3, label: "Ranch"},
    { value: 4, label: "Split"},
    { value: 5, label: "Contemporary"},
    { value: 6, label: "Log House"},
    { value: 7, label: "Mobile Home"},   
    { value: 8, label: "Split Level"},
    { value: 9, label: "Other"}
]

export const SelectStyleInput = (props:any) => {  
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    return (     
            <Select 
                {...props}                
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectStyleInput;