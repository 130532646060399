import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom"
import { useParams } from "react-router";
import { Edit, Trash, Lock, Check, Mail, Phone } from "react-feather"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import {getInvoiceInfoService} from "../machines/listing-machine/services"
import { Invoice } from '../contracts/response-models/invoice'

import {User} from '../contracts/response-models/user'
import {getUserInfoService} from "../machines/listing-machine/services"

import logo from "../assets/img/logo/zaglist-logo.png"
import "./custom.css";

import {
    Row,    
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Col,
    Input,  
    Media,  
    Table,
    CustomInput,
    Form,
    Button,
    Spinner
  } from "reactstrap"
  
  import "../assets/scss/pages/users.scss"
export const InvoicePreview = () => {
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<Invoice>({
    id: 0,
    title:"",
    description:""
  });
  const [user, setUser] = useState<User>({userId:0});

  useEffect(() => {
    async function loadInvoiceInfo() {
    setLoading(true);
    if (id > 0) {
      //const invoiceinfo = await getInvoiceInfoService(id);  
     // setInvoice(invoiceinfo);  

    }
    const userinfo = await getUserInfoService();  
    setUser(userinfo);
    setLoading(false);
  }
  loadInvoiceInfo();

  }, []);
  let { id } = useParams();

    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="Invoice Preview" breadCrumbParent="Invoices" breadCrumbActive="Invoice Preview" />
            <Card>             
       
        <CardHeader>
                <CardTitle>Invoice Preview</CardTitle>
              </CardHeader>
              <CardBody>
              {loading ? (<div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>) : (
            <Row>
      
            <Col className="invoice-wrapper" sm="12">
              <Card className="invoice-page">
                <CardBody>
                  <Row>
                    <Col md="6" sm="12" className="pt-1">
                      <Media className="pt-1">
                        <img src={logo} alt="logo" />
                      </Media>
                     
                    </Col>
                    <Col md="6" sm="12" className="text-right">
                      <h1>Invoice</h1>
                      <div className="invoice-details mt-2">
                        <h6>INVOICE NO.</h6>
                        <p>001/2020</p>
                        <h6 className="mt-2">INVOICE DATE</h6>
                        <p>10 Dec 2018</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col md="6" sm="12">
                      <h5>Recipient</h5>
                      <div className="recipient-info my-2">
                        <p>{user.firstName} {user.lastName}</p>
                        <p> {user.companyName} <br/>
                               {user.streetAddress}<br/>
                                {user.city}, {user.stateCD} {user.zip}<br/>
                               {user.tel} Office</p>
                        
                      </div>
                      <div className="recipient-contact pb-2">
                        <p>
                          <Mail size={15} className="mr-50" />
                          {user.userName}
                        </p>
                        <p>
                          <Phone size={15} className="mr-50" />
                         {user.cellno}
                        </p>
                      </div>
                    </Col>
                    <Col md="6" sm="12" className="text-right">
                      <h5>Zaglist.com</h5>
                      <div className="company-info my-2">
                        <p>9 N. Sherwood Court</p>
                        <p>Elyria, OH</p>
                        <p>94203</p>
                      </div>
                      <div className="company-contact">
                        <p>
                          <Mail size={15} className="mr-50" />
                          support@zaglist.com
                        </p>
                        <p>
                          <Phone size={15} className="mr-50" />
                          +91 999 999 9999
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div className="invoice-items-table pt-1">
                    <Row>
                      <Col sm="12">
                        <Table responsive borderless>
                          <thead>
                            <tr>
                              <th>DESCRIPTION</th>
                              <th>LISTING DAYS</th>
                              <th>RATE</th>
                              <th>AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Website Redesign</td>
                              <td>60</td>
                              <td>15 USD</td>
                              <td>90000 USD</td>
                            </tr>
                        
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                  <div className="invoice-total-table">
                    <Row>
                      <Col
                        sm={{ size: 7, offset: 5 }}
                        xs={{ size: 7, offset: 5 }}
                      >
                        <Table responsive borderless>
                          <tbody>
                            <tr>
                              <th>SUBTOTAL</th>
                              <td>114000 USD</td>
                            </tr>
                          
                            <tr>
                              <th>TOTAL</th>
                              <td>108300 USD</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-right pt-3 invoice-footer">
                    <p>
                      Transfer the amounts to the business amount below. Please
                      include invoice number on your check.
                    </p>
                    <p className="bank-details mb-0">
                      <span className="mr-4">
                        BANK: <strong>FTSBUS33</strong>
                      </span>
                      <span>
                        IBAN: <strong>G882-1111-2222-3333</strong>
                      </span>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
                
                )}
              </CardBody>
           
           
            </Card>
        </React.Fragment>
    )
}
export default InvoicePreview;