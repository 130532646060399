import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import {Property} from '../../../contracts/response-models/property'


import {PropertyStyle} from "./listingfields/property-style"
import {PropertyAvailableSqft} from "./listingfields/property-availableSqft"
import {PropertyParkingSpaces} from "./listingfields/property-parkingSpaces"
import {PropertyRentFeatures} from "./listingfields/property-rentfeatures"
interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsCOM = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    

        return (     
            <>   
              <Row>
        <PropertyStyle property={property} setProperty={setProperty}/>
  
        <PropertyAvailableSqft property={property} setProperty={setProperty}/>
  
        <PropertyParkingSpaces property={property} setProperty={setProperty}/>
  
       
        </Row>
        <Row>
        <PropertyRentFeatures property={property} setProperty={setProperty}/>
  
       
        </Row>
            
     
           
         </>
    )
}
    