import React, {useEffect, useRef} from 'react';
// Variables
const GOOGLE_MAP_API_KEY = 'AIzaSyBcaGEx6WhDgqShLtzSihaiZ20Y70noRRU';
const myLocation = { // CN Tower Landmark
  lat: 43.642567,
  lng: -79.387054
};
// styles
const mapStyles = {
  width: '100%',
  height: '400px',
};

interface mapProps{
    lat:any,
    lng:any,
    setLatlng:any    
}

function GoogleMapDrag({lat,lng,setLatlng}:mapProps) {
    myLocation.lat=lat;
    myLocation.lng=lng;

  // refs
  const googleMapRef = React.createRef<HTMLDivElement>();
  const googleMap = useRef(null);
  const marker = useRef(null);

  // helper functions  
  const createGoogleMap = () => 
        //@ts-ignore     
      new window.google.maps.Map(document.getElementById('google-map'), {
          zoom: 14,
          center: {
              lat: myLocation.lat,
              lng: myLocation.lng
          }
      });

  const createMarker = () =>{
      //@ts-ignore
      var m= new window.google.maps.Marker({
          position: {lat: myLocation.lat, lng: myLocation.lng},
          draggable:true,
          map: googleMap.current
      });
      //@ts-ignore
      window.google.maps.event.addListener(m, 'dragend', function(ev){
            var newPosition = m.getPosition();
            console.log(newPosition.lat());
            setLatlng({Latitude:newPosition.lat(),Longitude:newPosition.lng()})
      });
      return m;
    }

  // useEffect Hook
  useEffect(() => {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener('load', () => {
          googleMap.current = createGoogleMap();
          marker.current = createMarker()
      })
  },[]);

  return (
      <div
          id="google-map"
          ref={googleMapRef}
          style={mapStyles}
      />
  )

}

export default GoogleMapDrag