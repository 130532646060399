import React, { useState,useEffect, useRef } from "react";
import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, TabPane, TabContent,FormGroup} from "reactstrap"
import classnames from "classnames";
import { useParams } from "react-router";

import WizardListingAdd from "../components/@vuexy/wizard/WizardListingAdd"

import {PropertyType,SaleType} from '../contracts/enums'
import {Property} from '../contracts/response-models/property'
//import { Aminity } from "../contracts/response-models/aminity";

import {getPropertyInfoService} from "../machines/listing-machine/services"
 

interface AddNewListProps{
    currentMode:String
}

export const AddNewList = (props:AddNewListProps) => {
    //Set Defaults     
    const [saleType, setSaleType] = useState(SaleType.ForSale);
    const [propertyType, setPropertyType] = useState(PropertyType.SFH);   
    const [currentMode, setMode] = useState('Start')

    const [property, setProperty] = useState<Property>({ 
        saleType:SaleType.ForSale,
        propertyType:PropertyType.NONE,
             
    });

    let { id } = useParams();
   
  
    useEffect(() => {    
        async function getProperty() {
            if(id){
                const propertyinfo = await getPropertyInfoService(id);  
                setProperty(propertyinfo);
                setMode("Edit");
            }
           
        }
        getProperty();
    },[id]);

    
   // const [property, setProperty] = useState<Property>(propertyinfo);

    /*const imagearray =  ["image1.jpg", "image2.jpg", "image3.jpg"];    
    const someAmenities:Aminity[] = [{id:1,title:"Fireplace"}];
    const [property, setProperty] = useState<Property>({ 
        saleType:SaleType.ForSale,
        propertyType:PropertyType.SFH,
        street:'16 E Allendale Road',
        zipCode:'07458',
        stateCD:'NJ',
        countyId:2,
        areaId:81,
        noOfRooms:4,
        imageNames:imagearray,
        aminities:someAmenities,
    });
    */

  

    const btnStartClickHandler = (e:any) =>{
        e.preventDefault();
        setProperty({...property,saleType:saleType,propertyType:propertyType});
        //setProperty({...property,propertyType:propertyType});               
        setMode('Wizard')
    }

    const handleSaleTypeChange = (val:any) =>{
        setSaleType(val);
        setProperty({...property,saleType:val}); 
    }

    const handlePropertyTypeChange = (val:any) =>{
        setPropertyType(val);
        setProperty({...property,propertyType:val});
    }


    return (
        <React.Fragment>
            <Row>
                <Col lg="12" md="12">
    <h3>  {(id!=undefined && id>0) ? "Edit Listing":"Add New Listing" }   </h3>
                </Col>
            </Row>
            {id==undefined && currentMode=='Start' ? (
                <Card className="mt-2">
                    <CardBody> 
                    <Row className="mt-1">
                    <Col className="mx-auto" md="3" sm="3">             
                    <ListGroup className="list-group-horizontal">
                    <ListGroupItem 
                    className={classnames("col-md-6 text-center",{
                        active: saleType === SaleType.ForSale
                    })}
                    onClick={() => handleSaleTypeChange(SaleType.ForSale)}
                    >For Sale</ListGroupItem>
                    <ListGroupItem 
                    className={classnames("col-md-6 text-center",{
                        active: saleType === SaleType.ForRent
                    })}
                    onClick={() => { setSaleType(SaleType.ForRent)
                    if(propertyType==PropertyType.MFH || propertyType==PropertyType.LND )
                    { 
                        setPropertyType(PropertyType.SFH)    
                    }
                    }}>For Rent</ListGroupItem>                                                                               
                    </ListGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="mx-auto" md="3" sm="3">             
                    <ListGroup tag="div" className="mt-1">
                        <ListGroupItem
                        className={classnames({
                            active: propertyType === PropertyType.APT
                        })}
                        onClick={() => setPropertyType(PropertyType.APT)}
                        >
                        Apartment
                        </ListGroupItem>
                        <ListGroupItem
                        className={classnames({
                            active: propertyType === PropertyType.TWH
                        })}
                        onClick={() => setPropertyType(PropertyType.TWH)}
                        >
                        Townhouse
                        </ListGroupItem>
                        <ListGroupItem
                        className={classnames({
                            active: propertyType === PropertyType.SFH
                        })}
                        onClick={() => setPropertyType(PropertyType.SFH)}
                        >
                        Single Family
                        </ListGroupItem>
                        {saleType == SaleType.ForSale && ( 
                        <ListGroupItem
                        className={classnames({
                            active: propertyType === PropertyType.MFH
                        })}
                        onClick={() => setPropertyType(PropertyType.MFH)}
                        >
                        Multi Family
                        </ListGroupItem>
                        )}
                        <ListGroupItem
                        className={classnames({
                            active: propertyType === PropertyType.COM
                        })}
                        onClick={() => setPropertyType(PropertyType.COM)}
                        >
                        Commercial
                        </ListGroupItem>
                        {saleType == SaleType.ForSale && ( 
                        <ListGroupItem
                        className={classnames({
                            active: propertyType === PropertyType.LND
                        })}
                        onClick={() => setPropertyType(PropertyType.LND)}
                        >
                        Land
                        </ListGroupItem>
                        )}
                    </ListGroup>
                    <FormGroup row>
                    <Col md="12" className="mt-2 text-center">
                    <Button
                    color="success"
                    type="submit"
                    className="mr-1 mb-1"
                    onClick={btnStartClickHandler}
                    >
                    Start
                    </Button>
                    </Col>
                    
                    </FormGroup>
                    
                    </Col>
                    </Row>
                    </CardBody>
                </Card>    
            ):
            (
            <Row className="mt-2">
                <Col lg="12">
                    <WizardListingAdd property={property} setProperty={setProperty} />
                </Col>
            </Row>
            )
            }          
            
        </React.Fragment>
    )
}
export default AddNewList;