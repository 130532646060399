import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyAcres = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const  handleAcresChange = (value:any) => {        
        setProperty({...property,availavleAcres:value});
      }
      

        return (     
            <> 
                         <Col md="3" sm="12">
                <div className="d-inline mr-1"> Acres</div>                
                <NumericInput                 
                value={property.availavleAcres} 
                className={classnames("ml-1",{
                    invalid: (property.availavleAcres == undefined)
                    })}
                mobile style={mobileStyle}
                onChange={handleAcresChange}
                />
            </Col> 
          
        
         </>
    )
}
    