import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyAvailableSqft = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleAvailableSqftChange = (value:any) =>{
        setProperty({...property,availableSqft:value});
      }
    

        return (     
            <>         
                    <Col md="3" sm="12">
                <div className="d-inline mr-1">Available (Sqft)</div>
                <NumericInput      
                min={1} 
                max={1000}              
                  value={property.availableSqft}
                  className={classnames("ml-1",{
                    invalid: (property.availableSqft == undefined)
                  })}
                  mobile 
                  style={mobileStyle} 
                  onChange={handleAvailableSqftChange}
                />
            </Col>    
         </>
    )
}
    