import Geocode from "react-geocode";

import { listStates } from "../../api/location/list-states"
import { listCounties } from "../../api/location/list-counties"
import { listAreas } from "../../api/location/list-areas"
import { listAmenities } from "../../api/amenities/list-aminities"
import { listImprovement } from "../../api/improvements/list-improvements"
import { listZone } from "../../api/zones/list-zones"
import { listWaterfront } from "../../api/waterfront/list-waterfront"
import { createProperty } from "../../api/properties/create-property"
import { deleteProperty } from "../../api/properties/delete-property"
import { patchProperty } from "../../api/properties/patch-property"
import { listProperty } from "../../api/properties/list-properties"
import { getPropertyInfo } from "../../api/properties/get-property"
import { getUserInfo } from "../../api/users/get-user"
import { patchUser } from "../../api/users/patch-user"
import { createPost } from "../../api/posts/create-post"
import { getPostInfo } from "../../api/posts/get-post"
import { patchPost } from "../../api/posts/patch-post"
import { listPosts } from "../../api/posts/list-posts"
import { deletePost } from "../../api/posts/delete-post"
import { SaveOpenHouse } from "../../api/properties/save-open-house"
import { SaveEmbedVideo } from "../../api/properties/save-embed-video"
import { PatchExtendListing } from "../../api/properties/patch-extend-listing"
import { PatchVirtualTour } from "../../api/properties/patch-virtual-tour"
import { PatchChangePrice } from "../../api/properties/patch-change-price"
import { patchPropertyStatus } from "../../api/properties/patch-status"
import { getStripeClientSecret } from "../../api/payment/get-stripe-client-secret"
import { getStripeClientSecretFeatured } from "../../api/payment/get-stripe-client-secret-featured"
import { createInvoice } from "../../api/payment/create-invoice"
import { getInvoiceInfo } from "../../api/payment/get-invoice"
import { listInvoices } from "../../api/payment/list-invoices"



// Response Models //
import { PropertyType, SaleType } from "../../contracts/enums";
import { PaymentType } from "../../contracts/enums/payment-type";

import { Property } from "../../contracts/response-models/property"
import { User } from "../../contracts/response-models/user";
import { Post } from "../../contracts/response-models/post";
import { OpenHouse } from "../../contracts/response-models/openhouse";
import { EmbedVideo } from "../../contracts/response-models/embedvideo";
import { PropertyStatus } from "../../contracts/response-models/propertystatus";
import { ExtendListing } from "../../contracts/response-models/extendlisting";
import { VirtualTour } from "../../contracts/response-models/virtualtour";
import { ChangePrice } from "../../contracts/response-models/changeprice";
import { StripeSecret } from "../../contracts/response-models/stripe-client-secret"
import { CreateInvoice } from "../../contracts/response-models/create-invoice"


// Request Models //
import { CreatePropertyRequest } from "../../contracts/request-models/create-property";
import { PatchPropertyRequest } from "../../contracts/request-models/patch-property";
import { PatchUserRequest } from "../../contracts/request-models/patch-user";
import { CreatePostRequest } from "../../contracts/request-models/create-post"
import { PatchPostRequest } from "../../contracts/request-models/patch-post";
import { SaveOpenHouseRequest } from "../../contracts/request-models/save-open-house"
import { SaveEmbedVideoRequest } from "../../contracts/request-models/save-embed-video"
import { PatchPropertyStatusRequest } from "../../contracts/request-models/patch-property-status"
import { PatchExtendListingRequest } from "../../contracts/request-models/patch-extend-listing"
import { PatchVirtualTourRequest } from "../../contracts/request-models/patch-virtual-tour"
import { PatchChangePriceRequest } from "../../contracts/request-models/patch-change-price"
import { CreateSecretForNewListing } from "../../contracts/request-models/stripe-client-secret"
import { CreateSecretForFeatured } from "../../contracts/request-models/stripe-client-secrect-featured"
import { CreateInvoiceRequest } from "../../contracts/request-models/create-invoice-request"
import { RegisterUserRequest } from "../../contracts/request-models/register-user";
import { registerUser } from "../../api/users/register-user";
import { registerUserInfo } from "../../contracts/response-models/register-user";
import { getUserProfileInfo } from "../../api/users/get-user-profile";
import { ProfileSection } from "../../contracts/response-models/profile-section";
import { PatchUserProfileRequest } from "../../contracts/request-models/patch-user-profile";
import { patchUserProfile } from "../../api/users/patch-user-profile";


export const listStatesService = async () => {
  const states = await listStates();
  return states;
};

export const listCountyService = async (stateCD: string) => {
  const counties = await listCounties(stateCD);
  return counties;
};

export const listAreaService = async (countyId: number) => {
  const areas = await listAreas(countyId);
  return areas;
};

export const listAmenitiesService = async (propertyType: PropertyType, saleType: SaleType) => {
  const amenities = await listAmenities(propertyType, saleType);
  return amenities;
};

export const listImprovementsService = async () => {
  const improvements = await listImprovement();
  return improvements;
};

export const listZonesService = async () => {
  const zones = await listZone();
  return zones;
};

export const listWaterfrontService = async () => {
  const waterfront = await listWaterfront();
  return waterfront;
};


export const getLatlngByAddress = async (address: string) => {

  Geocode.setApiKey("AIzaSyBcaGEx6WhDgqShLtzSihaiZ20Y70noRRU");
  // Get latitude & longitude from address.
  Geocode.fromAddress("187 Hanuman Nagar, Kanakpura, Jaipur, Rajasthan, India 302012").then(
    response => {
      const { lat, lng } = response.results[0].geometry.location;
      console.log(lat, lng);
      return { lat, lng }
    },
    error => {
      console.error(error);
    }
  );
}


export const createPropertyService = async (property: Property) => {
  let request: CreatePropertyRequest = {
    propertyType: property.propertyType,
    saleType: property.saleType,
    listPrice: property.listPrice,
    isBrokerFee: property.isBrokerFee,
    brokerFee: property.brokerFee,
    isSecurityDeposit: property.isSecurityDeposit,
    securityDeposit: property.securityDeposit,
    annualTax: property.annualTax,
    commonCharges: property.commonCharges,
    maintenance: property.maintenance,
    allowedFinancing: property.allowedFinancing,
    street: property.street,
    complexName: property.complexName,
    areaId: property.areaId,
    zipCode: property.zipCode,
    units: property.units,
    isAddressPublic: property.isAddressPublic,
    isPetsAllowed: property.isPetsAllowed,
    hasParking: property.hasParking,
    approxSize: property.approxSize,
    noOfRooms: property.noOfRooms,
    noOfBedrooms: property.noOfBedrooms,
    noOfFullBaths: property.noOfFullBaths,
    noOfHalfBaths: property.noOfHalfBaths,
    styleId: property.styleId,
    garageId: property.garageId,
    basementId: property.basementId,
    yearBuiltId: property.yearBuiltId,
    conditionId: property.conditionId,
    buildingTypeId: property.buildingTypeId,
    securityId: property.securityId,
    leaseTermId: property.leaseTermId,
    heatingSourceId: property.heatingSourceId,
    coolingTypeId: property.coolingTypeId,
    exteriorFinishId: property.exteriorFinishId,
    lotSizeW: property.lotSizeW,
    lotSizeD: property.lotSizeD,
    availavleAcres: property.availavleAcres,
    totalAcres: property.totalAcres,
    smallestAcres: property.smallestAcres,
    noofLots: property.noofLots,
    ownershipType: property.ownershipType,
    propertyDescription: property.propertyDescription,
    topographyId: property.topographyId,
    wetLandId: property.wetLandId,
    virtualTLink: property.virtualTLink,
    embedVideo: property.embedVideo,
    latitude: property.latitude,
    longitude: property.longitude,
    availableDate: property.availableDate,
    startDate: property.startDate,
    endDate: property.endDate,
    status: 0,
    isFeatured: property.isFeatured,
    availableSqft: property.availableSqft,
    parkingSpaces: property.parkingSpaces,
    imageNames: property.imageNames
  };

  request.aminityIds = [];
  property.aminities?.forEach(element => {
    request.aminityIds?.push(element.id);
  });

  request.improvementIds = [];
  property.improvements?.forEach(element => {
    request.improvementIds?.push(element.id);
  });

  request.waterfrontIds = [];
  property.waterfronts?.forEach(element => {
    request.waterfrontIds?.push(element.id);
  });

  request.zoneIds = [];
  property.zones?.forEach(element => {
    request.zoneIds?.push(element.id);
  });

  //rentincludeIds:property.rentincludeIds,
  //unitsDetail:property.units,

  const createResponse = await createProperty(request);
  return createResponse;
};

export const listPropertiesService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const properties = await listProperty(paged, perpagearecord, sortedColumn, sortDirection);
  return properties;
};
export const DeletePropertyService = async (propertyid: any) => {
  const properties = await deleteProperty(propertyid);
  return properties;
};

export const getPropertyInfoService = async (id: number) => {
  const propertyinfo = await getPropertyInfo(id);
  return propertyinfo;
};


export const editPropertyService = async (property: Property, id: number) => {
  let request: PatchPropertyRequest = {
    listPrice: property.listPrice,
    isBrokerFee: property.isBrokerFee,
    brokerFee: property.brokerFee,
    isSecurityDeposit: property.isSecurityDeposit,
    securityDeposit: property.securityDeposit,
    annualTax: property.annualTax,
    commonCharges: property.commonCharges,
    maintenance: property.maintenance,
    allowedFinancing: property.allowedFinancing,
    street: property.street,
    complexName: property.complexName,
    areaId: property.areaId,
    zipCode: property.zipCode,
    units: property.units,
    isAddressPublic: property.isAddressPublic,
    isPetsAllowed: property.isPetsAllowed,
    hasParking: property.hasParking,
    approxSize: property.approxSize,
    noOfRooms: property.noOfRooms,
    noOfBedrooms: property.noOfBedrooms,
    noOfFullBaths: property.noOfFullBaths,
    noOfHalfBaths: property.noOfHalfBaths,
    styleId: property.styleId,
    garageId: property.garageId,
    basementId: property.basementId,
    yearBuiltId: property.yearBuiltId,
    conditionId: property.conditionId,
    buildingTypeId: property.buildingTypeId,
    securityId: property.securityId,
    leaseTermId: property.leaseTermId,
    heatingSourceId: property.heatingSourceId,
    coolingTypeId: property.coolingTypeId,
    exteriorFinishId: property.exteriorFinishId,
    lotSizeW: property.lotSizeW,
    lotSizeD: property.lotSizeD,
    availavleAcres: property.availavleAcres,
    totalAcres: property.totalAcres,
    smallestAcres: property.smallestAcres,
    noofLots: property.noofLots,
    ownershipType: property.ownershipType,
    propertyDescription: property.propertyDescription,
    topographyId: property.topographyId,
    wetLandId: property.wetLandId,
    virtualTLink: property.virtualTLink,
    embedVideo: property.embedVideo,
    latitude: property.latitude,
    longitude: property.longitude,
    availableDate: property.availableDate,
    startDate: property.startDate,
    endDate: property.endDate,
    status: property.status,
    isFeatured: property.isFeatured,
    availableSqft: property.availableSqft,
    parkingSpaces: property.parkingSpaces,
    imageNames: property.imageNames
  };

  request.aminityIds = [];
  property.aminities?.forEach(element => {
    request.aminityIds?.push(element.id);
  });

  request.improvementIds = [];
  property.improvements?.forEach(element => {
    request.improvementIds?.push(element.id);
  });

  request.waterfrontIds = [];
  property.waterfronts?.forEach(element => {
    request.waterfrontIds?.push(element.id);
  });

  request.zoneIds = [];
  property.zones?.forEach(element => {
    request.zoneIds?.push(element.id);
  });

  //rentincludeIds:property.rentincludeIds,
  //unitsDetail:property.units,

  const patchResponse = await patchProperty(id, request);
  return patchResponse;
};

// User Service Starts here //
export const getUserInfoService = async () => {
  const userinfo = await getUserInfo();
  return userinfo;
};

export const getUserProfileInfoService = async () => {
  const userinfo = await getUserProfileInfo();
  return userinfo;
};


export const editUserService = async (user: User, id: number) => {
  let request: PatchUserRequest = {
    userName: user.userName,
    firstName: user.firstName,
    lastName: user.lastName,
    title: user.title,
    tel: user.tel,
    cellno: user.cellno,
    companyName: user.companyName,
    streetAddress: user.streetAddress,
    city: user.city,
    stateCD: user.stateCD,
    zip: user.zip,
    userPhoto: user.userPhoto,
    website: user.website,
  };


  const patchResponse = await patchUser(id, request);
  return patchResponse;
};

export const editUserProfileService = async (user: User, id: number,profileSection:ProfileSection[]) => {
  let request: PatchUserRequest = {
    userName: user.userName,
    firstName: user.firstName,
    lastName: user.lastName,
    title: user.title,
    tel: user.tel,
    cellno: user.cellno,
    companyName: user.companyName,
    streetAddress: user.streetAddress,
    city: user.city,
    stateCD: user.stateCD,
    zip: user.zip,
    userPhoto: user.userPhotoFileName, 
    website: user.website,
    profileSections:profileSection
  }; 
  const patchResponse = await patchUser(id, request);
  return patchResponse;
};

// Post Service Starts here //

export const createPostService = async (post: Post) => {
  let request: CreatePostRequest = {
    title: post.title,
    photo: post.photo,
    description: post.description,
  };
  const createResponse = await createPost(request);
  return createResponse;
};

export const getPostInfoService = async (id: number) => {
  const postinfo = await getPostInfo(id);
  return postinfo;
};

export const editPostService = async (post: Post, id: number) => {
  let request: PatchPostRequest = {
    title: post.title,
    photo: post.photo,
    description: post.description,

  };
  const patchResponse = await patchPost(id, request);
  return patchResponse;
};

export const listPostsService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const posts = await listPosts(paged, perpagearecord, sortedColumn, sortDirection);
  return posts;
};

export const DeletePostService = async (postid: any) => {
  const post = await deletePost(postid);
  return post;
};

// Listing Modal Services Starts here //

export const saveOpenHouseService = async (openhouse: OpenHouse, propertyId?: number) => {
  let request: SaveOpenHouseRequest = {
    propertyId: openhouse.propertyId,
    ohNote: openhouse.ohNote,
    ohDate: openhouse.ohDate,
    startTime: openhouse.startTime,
    endTime: openhouse.endTime
  };
  const createResponse = await SaveOpenHouse(request, propertyId);
  return createResponse;
};

export const saveEmbedVideoService = async (embedvideo: EmbedVideo, propertyId?: number) => {
  let request: SaveEmbedVideoRequest = {
    embededVideo: embedvideo.embededVideo
  };
  const createResponse = await SaveEmbedVideo(request, propertyId);
  return createResponse;
};

export const propertyStatusService = async (propertyStatus: PropertyStatus, propertyId?: number) => {
  let request: PatchPropertyStatusRequest = {
    status: propertyStatus.status
  };
  const createResponse = await patchPropertyStatus(request, propertyId);
  return createResponse;
};

export const saveExtendListingService = async (extendlisting: ExtendListing, propertyId?: number) => {
  let request: PatchExtendListingRequest = {
    endDate: extendlisting.endDate
  };
  const createResponse = await PatchExtendListing(request, propertyId);
  return createResponse;
};

export const saveVirtualTourService = async (virtualtour: VirtualTour, propertyId?: number) => {
  let request: PatchVirtualTourRequest = {
    vTour: virtualtour.vTour
  };
  const createResponse = await PatchVirtualTour(request, propertyId);
  return createResponse;
};

export const saveChangePriceService = async (changeprice: ChangePrice, propertyId?: number) => {
  let request: PatchChangePriceRequest = {
    listPrice: changeprice.listPrice,
    changedOn: changeprice.changedOn
  };
  const createResponse = await PatchChangePrice(request, propertyId);
  return createResponse;
}

export const getStripeClientSecretListingservice = async (startDate?: string, endDate?: string, email?: string) => {
  let request: CreateSecretForNewListing = {
    email: email,
    startDate: startDate,
    endDate: endDate
  };
  const getResponse = await getStripeClientSecret(request);
  return getResponse;
};

export const getStripeClientSecretFeaturedervice = async (days?: string, paymentType?: PaymentType, email?: string) => {
  let request: CreateSecretForFeatured = {
    days: days,
    paymentType: paymentType,
    email: email,

  };
  const getResponse = await getStripeClientSecretFeatured(request);
  return getResponse;
};

export const createInvoiceService = async (paymentType: any, days: number, propertyid: any, paymentReferece: string) => {
  let request: CreateInvoiceRequest = {
    paymentType: paymentType,
    days: days,
    propertyId: propertyid,
    paymentReferece: paymentReferece
  };
  const createResponse = await createInvoice(request);
  return createResponse;
};

export const listInvoiceService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const invoices = await listInvoices(paged, perpagearecord, sortedColumn, sortDirection);
  return invoices;
};

export const getInvoiceInfoService = async (id: number) => {
  const invoiceinfo = await getInvoiceInfo(id);
  return invoiceinfo;
};

export const registerUserService = async (user: registerUserInfo) => {
  let request: RegisterUserRequest = {
    userName: user.userName,
    firstName: user.firstName,
    lastName: user.lastName,
    password: user.password,
  };
  const createResponse = await registerUser(request);
  return createResponse;
};








