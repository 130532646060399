import React,{useState,useEffect} from "react"; 
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,
   
} from "reactstrap"
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import {Property} from '../../contracts/response-models/property'
 
import { StripePromise } from "../../utils/loadStripe";
import { StripeFeaturedListingForm } from "./property-fl-form-modal";

interface PropertyIdProps {  
    property?:Property,
    toggle:any; 
    readyState:any;
  setReadyState:any;
}


export const PropertyFLModal = ({ property,toggle,readyState,setReadyState}:PropertyIdProps) => {  
  const stripePromise = StripePromise();
  const [loading, setLoading] = useState(false); 
 
        return (     
          <>   
          <ModalHeader toggle={toggle}  >
            Featured Listing 
          </ModalHeader>
          <ModalBody>
        {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
            <>
             <Elements stripe={stripePromise}>

             <StripeFeaturedListingForm  property={property} toggle={toggle} readyState={readyState} setReadyState={setReadyState}   />

              </Elements>
            </>
          )}
      </ModalBody>           
         </>
    )
}
    