import React, { useState, useRef, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Button,
    FormGroup,
    Input,
    Label,
    Form,
    Nav,    
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner
  } from "reactstrap"
import Checkbox from "../components/@vuexy/checkbox/CheckboxesVuexy"
import { Mail, Lock, Check } from "react-feather"
import { history } from "../history"
import registerImg from "../assets/img/pages/register.jpg"
import "../assets/scss/pages/authentication.scss" 
import {listStatesService, registerUserService} from '../machines/listing-machine/services'
import { ValidationMessage } from "../components/@vuexy/validation-message";
import { registerUserInfo } from "../contracts/response-models/register-user";
import PhoneInput from "react-phone-input-2";
import "../components/Register/css/custom-register.css"
import Select from "react-select"

  
export const RegisterPage = () => {

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<registerUserInfo>();
  const [validationMessage, setValidationMessage] = useState<string>();
  const defaultOption = { label: "Select", value: "" }
  const [selectedState, setSelectedState] = React.useState(defaultOption);
  const [stateItems, setStateItems] = React.useState([
    defaultOption
  ]);

  useEffect(() => {    
    async function loadStats() {
         
        
        const states = await listStatesService();    
        const stateOptions = states.map((state) => ({ label:state.stateTitle , value: state.stateCD }))
        setStateItems(stateOptions);
       
    }
    loadStats();
  },[]);
  const passwordValidate = (passwordValue:string) => {

    var isValidated = true;

    if (passwordValue != undefined && passwordValue != "") {
      if (passwordValue.length < 8) {
        setValidationMessage("Password must have minimum 8 characters");
        isValidated = false;
      }else if (!passwordValue.match(/[A-Z]+/)) { 
          setValidationMessage("Password must have a capital (uppercase) letter");
           isValidated = false;
        }else if (!passwordValue.match(/[a-z]+/)) { 
          setValidationMessage("Password must have a lowercase letter");
           isValidated = false;
        }else if (!passwordValue.match(/[0-9]+/)) { 
          setValidationMessage("Password must have a number");
           isValidated = false;
        }else if (!passwordValue.match(/[!@#$%\^&*(){}[\]<>?/|\-_]+/)) { 
          setValidationMessage("Password must have a special character");
           isValidated = false;
        }else{
          setValidationMessage("");
          isValidated = true;
        }

    }
    return isValidated;
  }
    let handleRegister = async (e:any) => {
        e.preventDefault()   
        var isValidated = true; 
        if (user?.terms !=true ) {
          setUser({ ...user, terms: false });
          isValidated = false
        }
        if (user?.tel == undefined || user.tel == "") {
          setUser({ ...user, tel: '' });
          isValidated = false
        }
        if (user?.zip == undefined || user.zip == "") {
          setUser({ ...user, zip: '' });
          isValidated = false
        }
        if (user?.stateCD == undefined || user.stateCD == "") {
          setUser({ ...user, stateCD: '' });
          isValidated = false
        }
        if (user?.city == undefined || user.city == "") {
          setUser({ ...user, city: '' });
          isValidated = false
        }
        if (user?.streetAddress == undefined || user.streetAddress == "") {
          setUser({ ...user, streetAddress: '' });
          isValidated = false
        }
        if (user?.companyName == undefined || user.companyName == "") {
          setUser({ ...user, companyName: '' });
          isValidated = false
        }

        if (user?.confirmPassword == undefined || user.confirmPassword == "") {
          setUser({ ...user, confirmPassword: '' });
          isValidated = false
        }
        if (user?.password == undefined || user.password == "") {
          setUser({ ...user, password: '' });
          setValidationMessage("Please type password");
          isValidated = false
        }
        if (user?.password!=undefined && !passwordValidate(user?.password)) {
          isValidated = false;
        }
        if (user?.password!=undefined &&  user.confirmPassword!=undefined && user.password!=user.confirmPassword) {
          setValidationMessage("Please type same password again");
          isValidated = false;
        }
       
        if (user?.userName == undefined || user.userName == "") {
          setUser({ ...user, userName: '' });
          isValidated = false
        }
        if (user?.firstName == undefined || user.firstName == "") {
          setUser({ ...user, firstName: '' });
          isValidated = false
        }


        if (isValidated == true && user!=undefined) {
        setLoading(true);
        var response = await registerUserService(user);
         if(response.userId!=null)
         {
          alert("Registered Successfully, please login your account.");
          window.location.href="/login";
         }else{
           alert("Something went wrong or your email id is already registered.")
         }
        setLoading(false);
        }
    }

    return (
        <div className="full-layout wrapper bg-full-screen-image blank-page dark-layout">
            <div className="app-content">
                <div className="content-wrapper">
                <div className="content-body">
                    <div className="flexbox-container">
                    <main className="main w-100">

        <Row className="m-0 justify-content-center">
        <Col
          sm="8"
          xl="7"
          lg="10"
          md="8"
          className="d-flex justify-content-center"
        >
          <Card className="bg-authentication rounded-0 mb-0 w-100">
            <Row className="m-0">
              <Col
                lg="6"
                className="d-lg-block d-none text-center align-self-center px-1 py-0"
              >
                <img className="mr-1" src={registerImg} alt="registerImg" />
              </Col>
              <Col lg="6" md="12" className="p-0">
                <Card className="rounded-0 mb-0 p-2">
                  <CardHeader className="pb-1 pt-50">
                    <CardTitle>
                      <h4 className="mb-0">Create Account</h4>
                    </CardTitle>
                  </CardHeader>
                  <p className="px-2 auth-title mb-0">
                    Fill the below form to create a new account.
                  </p>                 
                  <CardBody className="pt-1 pb-50">
                  {loading ? (<div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>) : (
                  <Form action="/" onSubmit={handleRegister}>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="First Name"
                             value={user?.firstName}   
                             onChange={(e)=> { setUser({ ...user, firstName: e.target.value })}}                         
                        />
                        <Label>First Name</Label>
                        <ValidationMessage isInvalid={(user?.firstName == undefined) || (user.firstName != '') ? false : true} errorMessage="Please enter first name" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Last Name"
                            value={user?.lastName}    
                            onChange={(e)=> { setUser({ ...user, lastName: e.target.value })}}                        
                        />
                        <Label>Last Name</Label>
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="email"
                            placeholder="Email"
                            value={user?.userName}    
                            onChange={(e)=> { setUser({ ...user, userName: e.target.value })}}                        
                        />
                        <Label>Email</Label>
                        <ValidationMessage isInvalid={(user?.userName == undefined) || (user.userName != '') ? false : true} errorMessage="Please enter email id" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="password"
                            placeholder="Password"
                            value={user?.password}    
                            onChange={(e)=> { setUser({ ...user, password: e.target.value })}}                              
                        />
                        <Label>Password</Label>
                        {(validationMessage != undefined && validationMessage != '') && (
              <>
                <div className="invalid-feedback d-block">{validationMessage}</div>
              </>
            )}
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={user?.confirmPassword} 
                            onChange={(e)=> { setUser({ ...user, confirmPassword: e.target.value })}}                           
                        />
                        <Label>Confirm Password</Label>
                        <ValidationMessage isInvalid={(user?.confirmPassword == undefined) || (user.confirmPassword != '') ? false : true} errorMessage="Please enter confirm password" />
                     
                        </FormGroup>
                   
                        <FormGroup>
                        <p className="mb-0">
                        Office Information : </p> 
                        </FormGroup> 
                        <FormGroup className="form-label-group1 show_label">
                        <Label>Cell</Label> 
                        <PhoneInput  country={'us'}  value={user?.cellno} 
                        placeholder="Cell"
                 onChange={(value)=> { setUser({ ...user, cellno: value })}}   /> 
                        
                        <ValidationMessage isInvalid={(user?.cellno == undefined) || (user.cellno != '') ? false : true} errorMessage="Please enter cell no" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Company Name"
                            value={user?.companyName}    
                            onChange={(e)=> { setUser({ ...user, companyName: e.target.value })}}                        
                        />
                        <Label>Company Name</Label>
                        <ValidationMessage isInvalid={(user?.companyName == undefined) || (user.companyName != '') ? false : true} errorMessage="Please enter company name" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Street Address"
                            value={user?.streetAddress}    
                            onChange={(e)=> { setUser({ ...user, streetAddress: e.target.value })}}                        
                        />
                        <Label>Street Address</Label>
                        <ValidationMessage isInvalid={(user?.streetAddress == undefined) || (user.streetAddress != '') ? false : true} errorMessage="Please enter street address" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="City"
                            value={user?.city}    
                            onChange={(e)=> { setUser({ ...user, city: e.target.value })}}                        
                        />
                        <Label>City</Label>
                        <ValidationMessage isInvalid={(user?.city == undefined) || (user.city != '') ? false : true} errorMessage="Please enter city" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Select    
                id="ddlState"        
                className="React"
                classNamePrefix="select" 
                defaultValue={selectedState}   
                options={stateItems}    
                value={selectedState} 
                onChange={(val:any)=> { setSelectedState(stateItems.filter(option => option.value==val.value)[0]);
                  setUser({...user,stateCD:val.value});}}
                />
                        <Input
                            type="text"
                            placeholder="State"
                            value={user?.stateCD}    
                            onChange={(e)=> { setUser({ ...user, stateCD: e.target.value })}}                        
                        />
                        <Label>State</Label>
                        <ValidationMessage isInvalid={(user?.stateCD == undefined) || (user.stateCD != '') ? false : true} errorMessage="Please select state" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group">
                        <Input
                            type="text"
                            placeholder="Zip"
                            value={user?.zip}    
                            onChange={(e)=> { setUser({ ...user, zip: e.target.value })}}                        
                        />
                        <Label>Zip</Label>
                        <ValidationMessage isInvalid={(user?.zip == undefined) || (user.zip != '') ? false : true} errorMessage="Please enter zipcode" />
                     
                        </FormGroup>
                        <FormGroup className="form-label-group1 show_label">
                        <Label>Office Tel</Label> 
                        <PhoneInput  country={'us'}  value={user?.tel} 
                        placeholder="Office Tel"
                 onChange={(value)=> { setUser({ ...user, tel: value })}}   /> 
                        
                        <ValidationMessage isInvalid={(user?.tel == undefined) || (user.tel != '') ? false : true} errorMessage="Please enter office tel no" />
                     
                        </FormGroup>
                        <FormGroup>
                        <Checkbox
                            color="primary"
                            icon={<Check className="vx-icon" size={16} />}
                            label="I accept the terms & conditions."
                            checked={user?.terms}
                            onChange={(e:any)=> { setUser({ ...user, terms: e.target.checked })}} 
                        />
                        <ValidationMessage isInvalid={(user?.terms == undefined) || (user.terms != false) ? false : true} errorMessage="Please accepts terms & condition" />
                     
                        </FormGroup>
                        <div className="d-flex justify-content-between">
                        <Button
                            Ripple
                            color="primary"
                            outline
                            onClick={() => {
                            history.push("/login")
                            }}
                        >
                            Login
                        </Button>
                        <Button Ripple color="primary" type="submit">
                            Register
                        </Button>
                        </div>
                    </Form>
                  )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      </main>
      </div>
      </div>
      </div>
      </div>
      </div>
    )
}

export default RegisterPage;