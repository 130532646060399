import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import {Property} from '../../../contracts/response-models/property'

import {PropertyRooms} from "./listingfields/property-rooms"
import {PropertyBedrooms} from "./listingfields/property-bedrooms"
import {PropertyFullBath} from "./listingfields/property-fullbaths"
import {PropertyHalfBath} from "./listingfields/property-halfbaths"
import {PropertyGarage} from "./listingfields/property-garage"
import {PropertyYearBuilt} from "./listingfields/property-yearbuilt"
import {PropertyHeatingSource} from "./listingfields/property-heatingsource"
import {PropertyBasement} from "./listingfields/property-basement"
import {PropertyCondition} from "./listingfields/property-condition"
import {PropertyCoolingType} from "./listingfields/property-coolingtype"
import {PropertyExteriorFinish} from "./listingfields/property-exteriorfinish"
import {PropertyStyle} from "./listingfields/property-style"
import {PropertyLotSize} from "./listingfields/property-lotsize"
import {PropertyAcres} from "./listingfields/property-acres"
import {PropertyParkingSpaces} from "./listingfields/property-parkingSpaces"
import { PropertyUnitDetails } from "./listingfields/property-unitdetails";
interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsMFHS = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    

        return (     
            <>    
               
               <PropertyUnitDetails property={property} setProperty={setProperty} />
        
          <Row className="mt-2">

          <PropertyParkingSpaces property={property} setProperty={setProperty} />
        
          <PropertyYearBuilt property={property} setProperty={setProperty} />
          <PropertyHeatingSource property={property} setProperty={setProperty} />
          <PropertyCoolingType property={property} setProperty={setProperty} />
               
          
          </Row>

            <Row className="mt-2">    
                           
            <PropertyLotSize property={property} setProperty={setProperty} />

               <PropertyAcres property={property} setProperty={setProperty} />
             
              
            </Row> 
           
         </>
    )
}
    