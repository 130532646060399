import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import {PropertyType,SaleType} from '../../contracts/enums'

interface PropertyDetailsStepProps {
    property?:Property,
    setProperty:any;    
}
export const PropertyPriceInfo = ({ property, setProperty}:PropertyDetailsStepProps) => {   

        return (     
            <>   
                          {property?.saleType==SaleType.ForSale && 
                        (
                       
 <Row className="mb-2 mt-0">
              <Col md="6" sm="6" className="text-right">
              Price  :
                  </Col>
                  <Col md="6" sm="6"  className="font-weight-bold" >
                  $ {property?.listPrice}
                  </Col>                 
              
              <Col md="6" sm="6" className="text-right">
              Annual Taxes  : 	
                  </Col>
                  <Col md="6" sm="6"  className="font-weight-bold" >
                  $ {property?.annualTax} 
                  </Col>
                  { property.propertyType==PropertyType.APT  &&
              ( 
                  <>
                <Col md="6" sm="6" className="text-right">
                Common Charges : 	
                    </Col>
                    <Col md="6" sm="6"  className="font-weight-bold" >
                    $ {property?.commonCharges}
                    </Col>
                    </>
              )}
                  </Row>
                  
                   )
               }

{property?.saleType==SaleType.ForRent && 
                        (
                       
                <Row className="mb-2 mt-0">
                            <Col md="6" sm="6" className="text-right">
                                Rent  : 
                            </Col>
                                <Col md="6" sm="6"  className="font-weight-bold" >
                                $ {property?.listPrice}
                                </Col>                 
                            
                            <Col md="6" sm="6" className="text-right">
                            Security Deposit  : 	
                                </Col>
                                <Col md="6" sm="6"  className="font-weight-bold" >
                                {(property.isSecurityDeposit!=undefined && property.isSecurityDeposit==true) ? "Yes":"No" }
                                </Col>
                                <Col md="6" sm="6" className="text-right">
                                Broker Fee  : 	
                                </Col>
                                <Col md="6" sm="6"  className="font-weight-bold" >
                                {(property.isBrokerFee!=undefined && property.isBrokerFee==true) ? "Yes":"No" }
                                </Col> 
                                <Col md="6" sm="6" className="text-right">
                                Lease Term :	
                                </Col>
                                <Col md="6" sm="6"  className="font-weight-bold" >
                                $ {property?.leaseTermId}
                                </Col> 
                                <Col md="6" sm="6" className="text-right">
                                Availablity  :	
                                </Col>
                                <Col md="6" sm="6"  className="font-weight-bold" >
                                   {property?.availableDate}
                                </Col> 
                                </Row>
                  
                   )
               }

         </>
    )
}
    