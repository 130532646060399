import React, { useState, useRef } from "react";
import { Row, Col } from "reactstrap"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

export const DashboardPage = () => {
    return (
        <React.Fragment>              
     
      </React.Fragment>
    )
}

export default DashboardPage;