import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyParkingSpaces = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleParkingSpacesChange = (e:any) =>{
      if(isNaN(e.target.value)!==false)
        {
          setProperty({...property,parkingSpaces:undefined});         
        }else{          
          setProperty({...property,parkingSpaces:e.target.value});         
        }
       
      }
    

        return (     
            <>         
                    <Col md="3" sm="12">
                <div className="d-inline mr-1">Parking Spaces</div>
                <Input                  
                  value={(property.parkingSpaces == undefined ? "" : property.parkingSpaces)}
                  className={classnames("ml-1",{
                    invalid: (property.parkingSpaces == undefined)
                  })}
                  mobile                   
                  onChange={handleParkingSpacesChange}
                />
            </Col>    
         </>
    )
}
    