import React,{useEffect, useState} from "react";

import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import { Link,Redirect } from "react-router-dom"

import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, FormGroup,Progress,Input, Spinner, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap"

import DataTable, { createTheme, IDataTableColumn } from "react-data-table-component"
import { ToastContainer,toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";

import  movies from "./movies";

import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Edit,
  Trash,
  Eye,
  ChevronDown,
} from "react-feather"

import { listPostsService } from "../machines/listing-machine/services"
import { Post } from "../contracts/response-models/post";

import { DeletePostService } from "../machines/listing-machine/services"


export const MyPosts = () => {
    const [posts, setPosts] = React.useState<Post[]>([]);
    const [loading,setLoading] = useState(false);
  
    const [readyState, setReadyState] = useState(false); 
    const [paged,setPropertiesInfo] = useState(1); 
    const [perpagearecord,setPagePerRecord] = useState(10); 
    const [sortedColumn,setSortedColumn]=useState('id');
    const [sortDirection,setSortDirection]=useState('desc');
    const [dataMessage,setDataMessage]=useState('Waiting...');

    useEffect(() => {    
        async function loadPosts() {
          setLoading(true);
         
            const posts = await listPostsService(paged,perpagearecord,sortedColumn,sortDirection);    
            setPosts(posts); 
              
            setLoading(false);  
        }
        
        loadPosts();

    },[readyState,paged,perpagearecord,sortedColumn,sortDirection]);

    const [showDeleteConfirm,setshowDeleteConfirm] = useState(false); 
    const [showDeleteMessage,setshowDeleteMessage] = useState(false); 
    const [totalrows,settotalrows]=useState(50);
    
    const [idToDelete,setIdToDelete] = useState(0);      
    
    const handleRemove=(id:number)=>{
      setIdToDelete(id);
      setshowDeleteConfirm(true);
    }

    const ChangePagination = (page:any,totalRows:any) => { setPropertiesInfo(page);}
    const ChangeRowsPerPage = (rowPerPage:number) => { setPagePerRecord(rowPerPage); }
    const sortProperties = (rows:any[],field:string,direction:any) => {  

      //return rows;
     // alert(field);
      return rows.reverse();

    }

    const sortResult = (column:any, sortDirection:any) =>{
     // alert(column.selector+", "+sortDirection);
      setSortedColumn(column.selector);
      setSortDirection(sortDirection);
      
    };

    const handleConfirmDelete = async (confirmRespons:boolean) =>{     
      if(!confirmRespons){
        setshowDeleteConfirm(confirmRespons);
        return;
      }
      if(idToDelete>0){       
        setshowDeleteConfirm(false);

        const rsp = await DeletePostService(idToDelete)
        toast.success("Post Deleted Successfully", {              
          position: toast.POSITION.TOP_RIGHT
        })
        setReadyState(true);
          
      }         
    }
    const handleDeleteMessage= (deleteMessage:boolean) => {
      setshowDeleteMessage(deleteMessage);
    }

    const columns = [
         
          {
            name: "Title",
            selector: "title",
            sortable: true,
            cell: (row:any) => (
              <p className="text-truncate  mb-0">
       
              <Link to={`/posts/preview/${row.id}`} className="cursor-pointer text-center">
                               {row.title}
               </Link>
             </p>
            )
          },
          
        {
          name: "DATE",
          selector: "startDate",
          sortable: true,
          cell: (row:any) => (
            
            <p   className="text-truncate  mb-0">
            {row.dateAddedFormated} 
                       
               
            </p>
          )
        },
        
          {
            name: "STATUS",
            selector: "status",
            sortable: true,
            cell: (row:any) => (
                <p   className="text-truncate  mb-0">
                {row.status==1 && ("Published")} 
                {(row.status==undefined || row.status==0) && ("UnPublished")}               
                   
                </p>
              )
          },
        {
          name: "ACTIONS",
          selector: "",
          sortable: false,
          cell: (row:any) => (  
            <>
            <UncontrolledButtonDropdown>
                           <DropdownToggle color="primary" size="sm" caret>
                             Action
                             <ChevronDown size={15} />
                           </DropdownToggle>
                           <DropdownMenu >
                             
                             <Link to={`/posts/add/${row.id}`} className="cursor-pointer  text-center">
                             <DropdownItem  tag="li" >Edit
                             </DropdownItem></Link>
                             <Link to={`/posts/`} className="cursor-pointer  text-center"  ><DropdownItem  onClick={() => handleRemove(row.id)} tag="li"  >
              Delete</DropdownItem>
             </Link>
                           </DropdownMenu>
                         </UncontrolledButtonDropdown>
            <div className="data-list-action d-none">  
            <Link to={`/posts/preview/${row.id}`} className="cursor-pointer">
                    <Eye className="cursor-pointer mr-1" size={20} />
                  </Link>   
                  <Link to={`/posts/add/${row.id}`} className="cursor-pointer">
                    <Edit className="cursor-pointer mr-1" size={20} />
                  </Link>
                  <Link to={`/posts/`}    className="cursor-pointer"  >
                    <Trash 
                      className="cursor-pointer"
                      size={20}
                      onClick={() => handleRemove(row.id)}        
                    />
                  </Link>
            </div> 
            </>             
          )
        },
        
      ];
    return (
        <React.Fragment>
        <Breadcrumbs breadCrumbTitle="My Posts" breadCrumbParent="Blog" breadCrumbActive="My Posts" />
        <ToastContainer />
      
        <Card className="mt-2">
                    <CardBody> 
                    {loading ? (
            <div className="d-flex justify-content-between mt-1">                               
            <Spinner size="lg"  className="primary float-right"/>
          </div>
          ):(
                    <DataTable
                      title="Posts"
                      columns={columns}
                      noHeader={true}
                      data={posts}
                      defaultSortField="id"
                      defaultSortAsc={false}          
                      pagination={true}
                      onChangePage={ChangePagination}
                      onChangeRowsPerPage={ChangeRowsPerPage}
                      paginationTotalRows={totalrows}
                      paginationPerPage={perpagearecord}
                      paginationServer={false}                      
                      selectableRows={false}
                      responsive={true}
                      sortServer={true}
                      onSort={sortResult}                                       
                      
                      striped={true}                    
                    />    )} 
                    </CardBody>

        </Card>  
        <SweetAlert title="Are you sure?" 
          warning
          show={showDeleteConfirm} 
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => handleConfirmDelete(true)}          
          onCancel={() => handleConfirmDelete(false)}        
        >
          You won't be able to revert this! 
        </SweetAlert>
        <SweetAlert success title="Deleted!" 
          confirmBtnBsStyle="success"
          show={showDeleteMessage}
          onConfirm={() => handleDeleteMessage(false)}     
        >
            <p className="sweet-alert-text">Your property deleted.</p>
        </SweetAlert>
        </React.Fragment>
    )
}