import React, { useState,useEffect, useRef } from "react";

import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, FormGroup,Progress,Input, Spinner} from "reactstrap"
import { useParams } from "react-router";
import { Link } from "react-router-dom"
import { Edit, Trash, Lock, Check } from "react-feather"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

import {getPropertyInfoService} from "../machines/listing-machine/services"
import {PropertyType,SaleType} from '../contracts/enums'
import {Property} from '../contracts/response-models/property'

import PropertyCarousel from "../components/PropertyPreview/PropertyCarousel"
import {PropertyDetailsSFHS} from "../components/PropertyPreview/PropertyDetail-SFHS"
import {PropertyDetailsAPT} from "../components/PropertyPreview/PropertyDetail-APT"
import {PropertyDetailsSFHR} from "../components/PropertyPreview/PropertyDetail-SFHR"
import {PropertyDetailsMFHS} from "../components/PropertyPreview/PropertyDetail-MFHS"
import {PropertyDetailsCOM} from "../components/PropertyPreview/PropertyDetail-COM"
import {PropertyDetailsLND} from "../components/PropertyPreview/PropertyDetail-LND"
import {PropertyPriceInfo} from "../components/PropertyPreview/PropertyPriceInfo"
import "../components/PropertyPreview/css/custom-component.css"

import { Aminity } from "../contracts/response-models/aminity";

import {
    Star,
    Truck,
    DollarSign,
    ShoppingCart,
    Heart,
    Facebook,
    Twitter,
    Youtube,
    Instagram,
    Award,
    Clock,
    Shield
  } from "react-feather"
import classnames from "classnames"

interface slideType{
  id:number;
  src:String;
}

export const PropertyPreview = () => {
  const [loading,setLoading] = useState(false);
  
    const [property, setProperty] = useState<Property>();
    let { id } = useParams();

    const [slides,setSlides] = useState<slideType[]>([]);
   
    useEffect(() => {    
        async function getProperty() {
          setLoading(true);
            if(id){
                const propertyinfo = await getPropertyInfoService(id);  
                setProperty(propertyinfo);   
                buildPhotos(propertyinfo?.imageNames);
            }
          setLoading(false); 
        }
        getProperty();
    },[id]);


    const buildPhotos=(imageNames?:String[])=>{

      const temp:slideType[] = [];
      imageNames?.forEach((element,index) => {
        console.log(element);
        temp.push({id:index+1,src:element});  
      });  

      setSlides(temp);
    }

    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="Property Preview" breadCrumbParent="Property" breadCrumbActive="Property Preview" />
           
            <Card className="overflow-hidden app-ecommerce-details">
          <CardBody className="pb-0">
            {loading? (
              <div className="d-flex justify-content-between mt-1">                               
              <Spinner size="lg"  className="primary float-right"/>
            </div>
            ):(
              <>
            <Row className="mb-2 mt-2"> 
            <Col className="mt-1 pl-0 text-right" sm="12">
                    <Button className="mr-1" color="primary" outline>
                      <Link to={`/listings/add/${property?.id}`}>
                        <Edit size={15} />
                        <span className="align-middle ml-50">Edit Property</span>
                      </Link>
                    </Button>                  
                  </Col>
                  </Row>
                  <Row className="mb-2 mt-2"> 
              <Col
                className="d-flex  justify-content-center carousel_container"
                sm="12"
                md="5"  
               
              >
                  <PropertyCarousel image={slides} />
                 
              </Col>
              <Col md="7" sm="12">
  
               
                <div className="d-flex flex-wrap">
                       
                </div>
                <hr />
                <Row  >
                  
                  <Col md="6" sm="6"> &nbsp;
                    </Col>
                      <Col md="6" sm="6"  >
                      <Row > 
                      <Col md="6" sm="6" className="text-right">
                      ZAG# :
                      </Col>
                      <Col md="6" sm="6"  className="font-weight-bold" >
                      {property?.id}
                      </Col>  </Row>
                      </Col>
                    </Row>

                <Row className="mb-2 ">
                  
              <Col md="6" sm="6">
              <Row className="mb-2 mt-0">
              
              <Col md="4" sm="6" className="text-right">
              Street  :
                  </Col>
                  <Col md="8" sm="6"  className="font-weight-bold" >
                  {property?.street}
                  </Col>
                 
              
              <Col md="4" sm="6" className="text-right">
              Town  : 	
                  </Col>
                  <Col md="8" sm="6"  className="font-weight-bold" >
                  {property?.areaTitle}
                  </Col>
                  </Row>
                  </Col>
                  <Col md="6" sm="6">
                 
                  {property && (
                    <PropertyPriceInfo property={property} setProperty={setProperty}/>
                  ) }
                  

                  </Col>

       
                  </Row>


                <hr />
                <h5>PROPERTY DETAILS</h5>
                <hr />

                {property?.propertyType==PropertyType.APT &&
              (
                <PropertyDetailsAPT property={property} setProperty={setProperty}/>
              )}                     

              {(property?.propertyType==PropertyType.SFH || property?.propertyType==PropertyType.TWH ) &&
              (
                <>
                {property.saleType==SaleType.ForSale && 
                  (
                    <PropertyDetailsSFHS property={property} setProperty={setProperty}/>
                  )
                }
                {property.saleType==SaleType.ForRent && 
                  (
                    <PropertyDetailsSFHR property={property} setProperty={setProperty}/>
                  )
                }
                </>
              )}   

               {property?.propertyType==PropertyType.MFH &&
              (
                    <PropertyDetailsMFHS property={property} setProperty={setProperty}/>
                 
              )}    
               {property?.propertyType==PropertyType.COM &&
              (
                <PropertyDetailsCOM property={property} setProperty={setProperty}/>
              )} 
              {property?.propertyType==PropertyType.LND &&
              (
                <>
                <PropertyDetailsLND property={property} setProperty={setProperty}/>
                 
                </>
              )}     
               

          
              </Col>
            </Row>
        
          <Row>
            <Col sm="12" className="m-50">
                <h5>PROPERTY DESCRIPTION</h5>
            <hr />
                <p>
                 {property?.propertyDescription}
                </p>
            </Col>
          </Row>
         
            {property?.propertyType!=PropertyType.LND && property?.propertyType!=PropertyType.COM &&
              ( 
               
                 <Row>
            <Col sm="12" className="m-50">
                <h5>AMENITIES</h5>
            <hr />
            <Row> 
                {                    
               property?.aminities?.map(function(amenity, i){
                   return (
                    <Col md="4" sm="6"  >
                   {amenity.title} 
                   </Col>
                   )
               })
            }
                  </Row>

                </Col>
                </Row>
               
              )} 
            
          
          
            {property?.propertyType==PropertyType.LND &&
              ( <>
                <Row>
                <Col sm="12" className="m-50">
                    <h5>IMPROVEMENTS</h5>
                <hr />
                <Row>

                  {
               property?.improvements?.map(function(improvement, i){
                   return (
                    <Col md="4" sm="6"  >
                    {improvement.title} 
                   </Col>
                   )
               })
               
            }
                       </Row>

                    </Col>
                    </Row>
                    <Row>
                <Col sm="12" className="m-50">
                    <h5>WATERFRONTS</h5>
                <hr />
                <Row>

                  {
               property?.waterfronts?.map(function(waterfront, i){
                   return (
                    <Col md="4" sm="6"  >
                    {waterfront.title} 
                   </Col>
                   )
               })
               
            }
                       </Row>

                    </Col>
                    </Row>
                    <Row>
                    <Col sm="12" className="m-50">
                    <h5>CURRENT ZONING & PERMITTED USE</h5>
                <hr />
                <Row>

                  {
               property?.zones?.map(function(zone, i){
                   return (
                    <Col md="4" sm="6"  >
                    {zone.title} 
                   </Col>
                   )
               })
               
            }
                       </Row>

                    </Col>
                    </Row>
            </>
              )} 
      </>
     )}
          </CardBody>
      
        </Card>
        </React.Fragment>
    )
}
export default PropertyPreview;