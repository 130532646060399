import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyRooms = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
        const  handleRoomChange = (value:any) => {        
        setProperty({...property,noOfRooms:value});
      }

        return (     
            <>         
            <Col md="3" sm="12">
                <div className="d-inline mr-1"># of Rooms</div>                
                <NumericInput 
                min={0} 
                max={99} 
                value={property.noOfRooms} 
                className={classnames("ml-1",{
                invalid: (property.noOfRooms == undefined)
                })}
                mobile style={mobileStyle}
                onChange={handleRoomChange}
                />
            </Col>           
         </>
    )
}
    