import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

import {SelectStyleInput} from '../../SelectInput/SelectStyleInput'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyStyle = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleStyleChange = (option:any) =>{    
        setProperty({...property,styleId:option.value});
      }
      

        return (     
            <>
                        <Col md="3" sm="12">
                <label>Style</label>
                <SelectStyleInput                               
                  defaultValue={property.styleId}
                  name="style"                
                  className={classnames("React",{
                    invalid: (property.styleId == undefined)
                  })}
                  onChange={handleStyleChange}
                />                
              </Col> 
         </>
    )
}
    