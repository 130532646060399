import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Input,
  CustomInput,
  Form,
  Button,
  Spinner
} from "reactstrap"
import Select from "react-select"
import PhoneInput from 'react-phone-input-2'

import { ValidationMessage } from "../components/@vuexy/validation-message"

import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"
import { SelectStateInput } from '../components/@vuexy/SelectInput/SelectStateInput'
import { listStatesService, listCountyService, listAreaService, getLatlngByAddress, getUserProfileInfoService, editUserProfileService } from "../machines/listing-machine/services"
import { User } from '../contracts/response-models/user'
import { getUserInfoService } from "../machines/listing-machine/services"
import { editUserService } from '../machines/listing-machine/services'

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/plugins/extensions/toastr.scss";
import { AgentProfile } from "../contracts/response-models/agent-profile";
import { ProfileSection } from "../contracts/response-models/profile-section";
import "../components/Profile/css/edit-profile.css"

export interface ProfileSectionDisplay {
  title?: string;
  section?: string;
  isvalidate?:boolean;
}

const defaultOption = { label: "Select One", value: "" }
const titleOptions = [
  { value: "", label: "Select One" },
  { value: "Salesperson", label: "Salesperson" },
  { value: "Sales Associate", label: "Sales Associate" },
  { value: "Broker Associate", label: "Broker Associate" },
  { value: "Broker", label: "Broker" }
]
const dataOptions = [
  { value: undefined, label: "Select" },
  { value: 1, label: "Level" },
  { value: 2, label: "Sloped" },
  { value: 3, label: "See Remarks" }
]


export const ProfileEdit = () => {
  const [loading, setLoading] = useState(false);

  //Validation States         
  const defaultOption = { label: "Select", value: "" }
  const loadingOption = { label: "Loading", value: "" }

  const [selectedState, setSelectedState] = React.useState(defaultOption);
  //For State Dropdown  
  const [stateItems, setStateItems] = React.useState([
    defaultOption
  ]);

  useEffect(() => {
    async function loadStats() {
      setLoading(true);
      setSelectedState(loadingOption);
      const states = await listStatesService();
      const stateOptions = states.map((state) => ({ label: state.stateTitle, value: state.stateCD }))
      setStateItems(stateOptions);
      setSelectedState(stateOptions.filter(option => option.value == user.stateCD)[0]);


      const userProfileinfo = await getUserProfileInfoService();
      setAgentProfile(userProfileinfo);
      const userinfo = userProfileinfo.user;
      setUser(userinfo);
      if (userProfileinfo.profileSections.length > 0) {
        setProfileSection(userProfileinfo.profileSections);
      }

      const foundTitle = userinfo.title != undefined && userinfo.title != "" ? titleOptions?.filter(option => option.value == userinfo.title)[0] : defaultOption;
      setSelectedTitle(foundTitle);

      const foundState = userinfo.stateCD != undefined && userinfo.stateCD != "" ? stateOptions.filter(option => option.value == userinfo.stateCD)[0] : defaultOption;
      console.log(foundState);
      setSelectedState(foundState);
      setLoading(false);
    }
    loadStats();
  }, []);
  const handleStateChange = (val: any) => {
    setSelectedState(stateItems.filter(option => option.value == val.value)[0]);
    setUser({ ...user, stateCD: val.value });
  }

  const [titleItems, setTitleItems] = React.useState([titleOptions]);
  const [selectedTitle, setSelectedTitle] = React.useState(defaultOption);

  const [user, setUser] = useState<User>({ userId: 0 });
  const [agentProfile, setAgentProfile] = useState<AgentProfile>();
  const [profileSection, setProfileSection] = useState<ProfileSection[]>([{
    title: '',
    section: '',
    isvalidate:false,
    
  }]);
 
  /// Handles to Set Users Info in 
  const handleFirstNameChange = (e: any) => {
    setUser({ ...user, firstName: e.target.value });
  }

  const handleLastNameChange = (e: any) => {
    setUser({ ...user, lastName: e.target.value });
  }


  const handleTitleChange = (val: any) => {
    setSelectedTitle(titleOptions?.filter(option => option.value == val.value)[0]);
    setUser({ ...user, title: val.value })
  }

  const handleEmailChange = (e: any) => {
    setUser({ ...user, userName: e.target.value });
  }

  const handleCellChange = (value: any) => {
    setUser({ ...user, cellno: value });
  }

  const [isUploading, setIsUploading] = useState(false);

  const handlePhotoUploadChange = (e: any) => {

    setIsUploading(true);
    const filesUpload = e.target.files;
    const formData = new FormData()

    formData.append('files', filesUpload[0])
    uploadFiles(formData);
  }
  const uploadFiles = (formData: any) => {
    fetch('https://api.zaglist.com/api/upload', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {

        //alert(data[0].fileName);
        setUser({ ...user, userPhoto: data[0].fileName });
        setIsUploading(false);
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleCompanyNameChange = (e: any) => {
    setUser({ ...user, companyName: e.target.value });
  }

  const handleStreetAddressChange = (e: any) => {
    setUser({ ...user, streetAddress: e.target.value });
  }

  const handleCityChange = (e: any) => {
    setUser({ ...user, city: e.target.value });
  }

  const handleZipChange = (e: any) => {
    setUser({ ...user, zip: e.target.value });
  }

  const handleTelChange = (value: any) => {
    setUser({ ...user, tel: value });
  }
  
  const handleWebsiteChange = (e: any) => {
    setUser({ ...user, website: e.target.value });
  }

  const removeProfileSection = (e: any,index:number) => {
    e.preventDefault() 
    
    let profileTemp = [...profileSection]; 
    if (index > -1) {
      profileTemp.splice(index, 1);
         
    }
    setProfileSection(profileTemp);
  }


  const saveUser = async (e: any) => {
    e.preventDefault() 
    var isValidated = true;
    if (user.firstName == undefined || user.firstName == "") {
      setUser({ ...user, firstName: '' });
      isValidated = false
    }
    if (user.lastName == undefined || user.lastName == "") {
      setUser({ ...user, lastName: '' });
      isValidated = false
    }
    if (user.title == undefined || user.title == "") {
      setUser({ ...user, title: '' });
      isValidated = false
    }
    if (user.userName == undefined || user.userName == "") {
      setUser({ ...user, userName: '' });
      isValidated = false
    }
    if (user.cellno == undefined || user.cellno == "") {
      setUser({ ...user, cellno: '' });
      isValidated = false
    }
    if (user.userPhoto == undefined || user.userPhoto == "") {
      setUser({ ...user, userPhoto: '' });
      // isValidated = false
    }
    if (user.companyName == undefined || user.companyName == "") {
      setUser({ ...user, companyName: '' });
      isValidated = false
    }
    if (user.streetAddress == undefined || user.streetAddress == "") {
      setUser({ ...user, streetAddress: '' });
      isValidated = false
    }
    if (user.city == undefined || user.city == "") {
      setUser({ ...user, city: '' });
      isValidated = false
    }
    if (user.stateCD == undefined || user.stateCD == "") {
      setUser({ ...user, stateCD: '' });
      isValidated = false
    }
    if (user.zip == undefined || user.zip == "") {
      setUser({ ...user, zip: '' });
      isValidated = false
    }
    if (user.tel == undefined || user.tel == "") {
      setUser({ ...user, tel: '' });
      isValidated = false
    } 
    if (user.website == undefined || user.website == "") {
      setUser({ ...user, website: '' });
      isValidated = false
    } 
        const profileTemp = [...profileSection]; 
        profileTemp?.map((profileInfo, index) => {
         
            if (profileInfo.title == '') {
              profileInfo.isvalidate = true;
                isValidated=false;
            }
        }
    
    )
    setProfileSection(profileTemp); 

    if (isValidated == true) {
      setLoading(true);
      const response = await editUserProfileService(user, 0,profileSection);
      toast.success("Profile Updated Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })
      window.location.href="/preview-profile";
      setLoading(false);
    }

  }


  return (
    <React.Fragment>
      <Breadcrumbs breadCrumbTitle="Edit Profile" breadCrumbParent="Profile" breadCrumbActive="Edit Profile" />


      <Card>
        <CardBody>
          <ToastContainer />

          {!loading ? (
            <Row className="mt-1">
              <Col className="mx-auto" md="7" sm="12">
          <h3 className="mb-2 text-left">Agent Information {user.userId}</h3>
                <Form>
                  <FormGroup row>
                    <Col md="3">
                      <span>First Name</span>
                    </Col>
                    <Col md="9">
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={user?.firstName}
                        onChange={handleFirstNameChange}
                        placeholder="First Name"
                      />
                      <ValidationMessage isInvalid={(user.firstName == undefined) || (user.firstName != '') ? false : true} errorMessage="Please enter your first name" />
                    </Col>

                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Last Name</span>
                    </Col>
                    <Col md="9">
                      <Input
                        type="text"
                        name="lastName"
                        value={user?.lastName}
                        onChange={handleLastNameChange}
                        id="lastName"
                        placeholder="Last Name"
                      />
                      <ValidationMessage isInvalid={(user.lastName == undefined) || (user.lastName != '') ? false : true} errorMessage="Please enter your last name" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Title</span>
                    </Col>
                    <Col md="9">
                      <Select
                        className="React"
                        classNamePrefix="select"
                        value={selectedTitle}
                        name="title"
                        onChange={handleTitleChange}
                        options={titleOptions}
                      />
                      <ValidationMessage isInvalid={(user.title == undefined) || (user.title != '') ? false : true} errorMessage="Please select title" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Email</span>
                    </Col>
                    <Col md="9">
                      <Input
                        type="email"
                        name="Email"
                        id="Email"
                        value={user?.userName}
                        onChange={handleEmailChange}
                        placeholder="Email"
                      />
                      <ValidationMessage isInvalid={(user.userName == undefined) || (user.userName != '') ? false : true} errorMessage="Please enter your email" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Cell</span>
                    </Col>
                    <Col md="9">
                      {user?.cellno != null ? (
                        <PhoneInput country={'us'} value={user?.cellno}
                          onChange={handleCellChange} />
                      ) : (
                          <PhoneInput country={'us'} value=""
                            onChange={handleCellChange} />
                        )}
                      <ValidationMessage isInvalid={(user.cellno == undefined) || (user.cellno != '') ? false : true} errorMessage="Please enter your cell no" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      Upload Photo
                </Col>
                    <Col md="9">
                      <CustomInput
                        type="file"
                        id="userPhoto"
                        name="userPhoto"
                        onChange={handlePhotoUploadChange}
                      />
                      {isUploading && ("Uploading...")}  <br />
                      {(user.userPhoto != undefined) && (user.userPhoto != '') && (
                        <> <img className="img-responsive" height="100" src={user.userPhoto} />
                        </>
                      )}


                      <ValidationMessage isInvalid={(user.userPhoto == undefined) || (user.userPhoto != '') ? false : true} errorMessage="Please upload your profile photo" />

                    </Col>
                  </FormGroup>
                  <h3 className="mb-2 text-left">Office Information</h3>
                  <FormGroup row>
                    <Col md="3">
                      <span>Company Name</span>
                    </Col>
                    <Col md="9">
                      <Input
                        type="text"
                        name="companyName"
                        id="companyName"
                        placeholder="Company Name"
                        value={user?.companyName}
                        onChange={handleCompanyNameChange}
                      />
                      <ValidationMessage isInvalid={(user.companyName == undefined) || (user.companyName != '') ? false : true} errorMessage="Please enter your company name" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Street Address</span>
                    </Col>
                    <Col md="9">
                      <Input
                        type="text"
                        name="streetAddress"
                        id="streetAddress"
                        placeholder="Street Address"
                        value={user?.streetAddress}
                        onChange={handleStreetAddressChange}
                      />
                      <ValidationMessage isInvalid={(user.streetAddress == undefined) || (user.streetAddress != '') ? false : true} errorMessage="Please enter your street address" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>City</span>
                    </Col>
                    <Col md="9">
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        value={user?.city}
                        onChange={handleCityChange}
                      />
                      <ValidationMessage isInvalid={(user.city == undefined) || (user.city != '') ? false : true} errorMessage="Please enter your city" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>State</span>
                    </Col>
                    <Col md="9">
                      <Select
                        id="ddlState"
                        className="React"
                        classNamePrefix="select"
                        defaultValue={selectedState}
                        options={stateItems}
                        value={selectedState}
                        onChange={handleStateChange}
                      />
                      <ValidationMessage isInvalid={(user.stateCD == undefined) || (user.stateCD != '') ? false : true} errorMessage="Please select your state" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Zip</span>
                    </Col>
                    <Col md="9">
                      <Input
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="Zip"
                        value={user?.zip}
                        onChange={handleZipChange}
                        maxLength={5}
                      />
                      <ValidationMessage isInvalid={(user.zip == undefined) || (user.zip != '') ? false : true} errorMessage="Please enter your zip code" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Office Tel</span>
                    </Col>
                    <Col md="9">
                      {user?.tel != null ? (
                        <PhoneInput country={'us'} value={user?.tel}
                          onChange={handleTelChange} />
                      ) : (
                          <PhoneInput country={'us'} value=""
                            onChange={handleTelChange} />
                        )}

                      <ValidationMessage isInvalid={(user.tel == undefined) || (user.tel != '') ? false : true} errorMessage="Please enter your office tel no" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Website</span>
                    </Col>
                    <Col md="9">
                      <Input
                        type="text"
                        name="website"
                        id="website"
                        placeholder="Website"
                        value={user?.website}
                        onChange={handleWebsiteChange}
                      />
                      <ValidationMessage isInvalid={(user.website == undefined) || (user.website != '') ? false : true} errorMessage="Please enter your website" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <span>Profile Section</span>
                    </Col>
                    <Col md="9"  >
                      <div className="profileSectionContainer">
                      {profileSection != undefined && (
                        profileSection?.map(function (profileInfo, i) {
                          return (<>
                          {profileSection.length>1 && (
                              <div className="removeButtonContainer">
                    <Button 
                      type="button"
                      color="red"
                      className="removeButton"
                                        
                      onClick={(e) => { 
                        removeProfileSection(e,i);
                      }}
                    >
                     - 
                </Button></div>)}
                          <label></label>
                            <Input
                              type="text"
                              name="profileTitle"

                              placeholder="Title"
                              value={profileInfo.title}
                              onChange={(e) => {
                                const newArray = [...profileSection];
                                newArray[i] = { ...newArray[i], title: e.target.value };
                                setProfileSection(newArray);

                              }}
                            />
                             {profileInfo.isvalidate!=undefined && profileInfo.isvalidate==true &&(
                            <ValidationMessage isInvalid={(profileInfo.title == undefined) || (profileInfo.title != '') ? false : true} errorMessage="Please enter profile title." />
                            )}
                            <br />
                            <textarea
                              name="profileDesc"
                              className="form-control"
                              value={profileInfo.section}
                              placeholder="Desc"
                              onChange={(e) => {
                                const newArray = [...profileSection];
                                newArray[i] = { ...newArray[i], section: e.target.value };
                                setProfileSection(newArray);

                              }}
                            ></textarea>
                            {profileInfo.isvalidate!=undefined && profileInfo.isvalidate==true &&(
                            <ValidationMessage isInvalid={(profileInfo.section == undefined) || (profileInfo.section != '') ? false : true} errorMessage="Please enter profile desc." />
                            )}
                              <hr/>
                            </>)
                        }))}
                      </div>
                    </Col>
                    <div className="addButtonContainer">
                    <Button
                      color="primary"
                      type="button"
                      className="addButton"                    
                      onClick={(e) => {
                        const newArray = [...profileSection];
                        newArray?.push({
                          title: '',
                          section: '', 
                          isvalidate:false,
                        });
                        setProfileSection(newArray);
                      }}
                    >
                      +
                </Button></div>
                  </FormGroup>

                  <FormGroup row><Col md="12" className="mb-2 ml-5"></Col>
                    <Col md={{ size: 8, offset: 4 }}>

                      <Button
                        color="primary"
                        type="submit"
                        className="mr-1 mb-1"
                        onClick={saveUser}
                      >
                        Submit
                </Button>
                      <Button
                        outline
                        color="warning"
                        type="reset"
                        className="mb-1"
                      >
                        Reset
                </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          ) : (
              <div className="d-flex justify-content-between mt-1">
                <Spinner size="lg" className="primary float-right" />
              </div>
            )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default ProfileEdit;