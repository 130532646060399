import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyTotalAcres = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const  handleTotalAcresChange = (value:any) => {        
        setProperty({...property,totalAcres:value});
      }
      

        return (     
            <>

                        <Col md="3" sm="12">
                <div className="d-inline mr-1"> Total Acres</div>                
                <NumericInput   value={property.totalAcres} mobile onChange={handleTotalAcresChange} style={mobileStyle} />
            </Col> 
         </>
    )
}
    