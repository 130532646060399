import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsAPT = ({ property, setProperty}:PropertyDetailsStepProps) => {   
        return (     
            <>   
            
              <Row className="mb-2 mt-2">
                <Col md="4" sm="6">
              <Row  >
              
              <Col md="6" sm="6"   className="mb-1 text-right">
              Bedrooms  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfBedrooms}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Full Baths  :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfFullBaths}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Half Baths  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.noOfHalfBaths}
              </Col>
              </Row>
    
                  </Col> 
                  <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Parking :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {(property.hasParking!=undefined && property.hasParking==true) ? "Yes":"No" }
              
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Apt Condition :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.condition}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Building Age :	
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.yearBuilt}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Complex Name :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.complexName}
              </Col>
              </Row>
    
                  </Col> 
                  <Col md="4" sm="6">
              <Row  >
              <Col md="6" sm="6"   className="mb-1  text-right">
              Building Type :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.buildingType}
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Security  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              
              {(property.isSecurityDeposit!=undefined && property.isSecurityDeposit==true) ? "Yes":"No" }
             
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Pets Allowed? :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {(property.isPetsAllowed!=undefined && property.isPetsAllowed==true) ? "Yes":"No" }
             
              </Col>
              <Col md="6" sm="6"   className="mb-1 text-right">
              Size  :
              </Col>
              <Col md="6" sm="6"  className="font-weight-bold" > 
              {property?.approxSize}
              </Col>
              </Row>
    
                  </Col> 

                  </Row>
         </>
    )
}
    